// -----------------------------------------------------------------Imports---
import {
  Key,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { toast } from 'react-toastify';

import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { style } from './AddDeliveryAddress.style';

import {
  gridKeys,
  DeliveryAddressGridKey,
  DeliveryAddressModel,
} from '../../models/DeliveryAddressModel';

import DeliveryAddressService from '../../services/deliveryAddresses/deliveryAddressService';
import { PartnerModel } from '../../models/PartnerModel';
import ResponseModel from '../../models/ResponseModel';
import { GlobalContext, GlobalContextType } from '../../App';

// -----------------------------------------------------------------Exports---
export default function AddDeliveryAddress(props: AddDeliveryAddressProps): JSX.Element {
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddressModel>({} as DeliveryAddressModel);
  const [id] = useState<string | number | undefined>(props.id);
  const [partnerForDelivery] = useState<PartnerModel | null>(props.partnerForDelivery ?? null);
  const globalContext = useContext<GlobalContextType | null>(GlobalContext);

  //get a deliveryAddress to update, if id is available
  const getDeliveryAddress = useCallback(async (id: string): Promise<void> => {
    let response: ResponseModel = await DeliveryAddressService.getDeliveryAddress(id, globalContext!.logOut);
    
    if (response.error !== null) {
      toast.error(response.error?.message);
    }
    else {
      setDeliveryAddress({...response.data} as DeliveryAddressModel)
    }
  }, [globalContext]);

  //saving the new deliveryAddress
  const saveDeliveryAddress = async (): Promise<void> => {
    //guards
    if (deliveryAddress.name === undefined || deliveryAddress.name!.length < 3) { toast.error('A név legalább 3 karakter kell legyen'); return; }

    //sending the request
    let response: ResponseModel;
    if(id === undefined)
      response = await DeliveryAddressService.addDeliveryAddress({...deliveryAddress, partnerCode: partnerForDelivery?.partnerCode}, globalContext!.logOut);
    else
      response = await DeliveryAddressService.updateDeliveryAddress(deliveryAddress, id.toString(), globalContext!.logOut);

    //showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
    }
    else {
      if(id === undefined)
        toast.success('Sikeres szállítási cím rögzítés');
      else
        toast.success('Sikeres szállítási cím frissítés');
      props.setDeliverySetterOpen(false);
      props.getPartners();
    }
  }

  //updating the new deliveryAddress state
  const onTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string): void => {
    let newDeliveryAddress: DeliveryAddressModel = { ...deliveryAddress };
    (newDeliveryAddress as any)[key] = e.target.value;
    setDeliveryAddress(newDeliveryAddress);
  };

  // loading in the partners address for the 1st delivery address
  const onLoadDefault = () => {
    let _deliveryAddress: DeliveryAddressModel = {
      name: deliveryAddress?.name,
      postalCode: partnerForDelivery?.postalCode,
      city: partnerForDelivery?.city,
      streetName: partnerForDelivery?.streetName,
      streetType: partnerForDelivery?.streetType,
      houseNumber: partnerForDelivery?.houseNumber,
      building: partnerForDelivery?.building,
      staircase: partnerForDelivery?.staircase,
      floor: partnerForDelivery?.floor,
      door: partnerForDelivery?.door,
      parcelNumber: partnerForDelivery?.parcelNumber
    } as DeliveryAddressModel;
    setDeliveryAddress(_deliveryAddress);
  };

  //devnote: somehow this is enough
  useEffect(() => {
    if(id !== undefined){
      getDeliveryAddress(id.toString());
    }
  }, [id, getDeliveryAddress]);

  // --------------------------------------------------------------Return---
  return (
    <Paper style={style.paper}>
      {/* Title */}
      <Typography variant="h3" style={style.title}>        
        {id === undefined && 
          'Szállítási cím hozzáadása'
        }
        {id !== undefined &&
          'Szállítási cím frissítése'
        }
      </Typography>

      {/* Inputs */}
      <Grid container spacing={4}>
        {gridKeys.filter((gridKey: DeliveryAddressGridKey): boolean => gridKey.inputHidden !== true)
          .map((gridKey: DeliveryAddressGridKey, i: Key): JSX.Element => {
            return (
              <Grid
                item
                key={i}
                md={gridKey.md ?? 3}
                sm={gridKey.sm ?? 6}
                xs={gridKey.xs ?? 12}
              >
                <Typography
                  style={style.gridLabel}
                  align={gridKey.align ?? 'left'}
                >
                  {gridKey.label}
                </Typography>
                <TextField value={(deliveryAddress as any)[gridKey.key] ?? ''} variant="standard" onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onTextChange(e, gridKey.key)} />
              </Grid>
            )
          })}
      </Grid>

      {/* Footer buttons */}
      <Button color="success" onClick={onLoadDefault} variant="contained" style={style.saveButton}>
        Alapértelmezett cím betöltése
      </Button>
      <Button onClick={saveDeliveryAddress} variant="contained" style={style.saveButton}>
        {id === undefined && 
          'Rögzítés'
        }
        {id !== undefined &&
          'Frissítés'
        }
      </Button>
      <Button color="error" onClick={() => props.setDeliverySetterOpen(false)} variant="contained" style={style.saveButton}>
        Mégse
      </Button>
    </Paper>
  );
}

type AddDeliveryAddressProps = {
  partnerForDelivery: PartnerModel | null,
  id: string | number | undefined,
  setDeliverySetterOpen: React.Dispatch<React.SetStateAction<boolean>>,
  getPartners: () => Promise<void>,
};
