// -----------------------------------------------------------------Exports---
export const style: any = {
  title: {
    marginBottom: 32
  },
  container: {
    maxWidth: 'none',
    display: 'flex',
    justifyContent: 'space-between',
  },
  navButton: {
    height: 56,
    margin: 16,
  },
  tableHeadCell: {
    borderBottom: 'none',
  },
  paper: {
    margin: 32,
    padding: 16,
  },
  filterTextField: {
    margin: 16,
    marginBottom: 0,
  },
  tableContainer: {
    padding: 16,
  },
  dataRow: {
    borderBottom: 'gray 1px solid',
    borderTop: 'gray 1px solid',
  },
  dataCell: {
    borderBottom: 'none',
    whiteSpace: 'break-spaces',
  },
  accordionCell: {
    borderBottom: 'none',
    padding: 0,
  },
  accordionDetails:{
    padding: 16,
  },
  gridContainer:{
    paddingBottom: 8,
    paddingTop: 8,
  },
  gridLabel: {
    fontWeight: 600,
    fontSize: 18,
  },
  buttonContainer:{
    maxWidth: 'none',
    justifyContent: 'flex-end',
    display: 'flex',
    gap: 16,
    marginBottom: 16,
    marginTop: 16,
    padding: 0,
  },
  modalBox:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
}
