import { GridSize, InputProps } from "@mui/material";
import { TerminalContractModel } from "./TerminalContractModal";
import { TerminalRequestModel } from "./TerminalRequestModel";
import { TerminalTypeModel } from "./TerminalTypeModel";

export type TerminalModel = {
  terminalCode: number,
  terminalTypeCode?: number | null,
  terminalType?: TerminalTypeModel | null,
  terminalOwnerCode?: null,
  terminalOwner?: any | null,
  serviceCode?: string | null,
  service?: any | null,
  terminalId?: string | null,
  serialNumber?: string | null,
  managerCard?: string | null,
  date?: string,
  comment?: string | null,
  externalInvoiceId?: string | null,
  imsi?: string | null,
  isReplacementNeed?: boolean | null,
  pin1?: string | null,
  pin2?: string | null,
  puk?: string | null,
  state?: string | null,
  warehouseCode?: string | null,
  updatedBy?: string | null,
  updatedByUser?: any
  updatedAt?: string | null,
  tid?: any | null,
  tidCode?: number | null,
  terminalNeed?: TerminalRequestModel | null,
  terminalContract?: TerminalContractModel | null,
  isTakenBack?: boolean | null,
  //extended values
  tidString?: string | null,
  outDate?: string | null,
  inDate?: string | null,
  partner?: string | null,
  partnerAddressName?: string | null,
  partnerFullAddress?: string | null,
  terminalTypeName?: string | null,
  statusName?: string | null,
  fee?: number | null,
}

export type TerminalTableKey = {
  label: string,
  key: string,
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify' | null,
}

export type TerminalGridKey = {
  label: string,
  key: string,
  inputHidden?: true | null,
  inputDisabled?: true | null,
  md?: GridSize | null,
  sm?: GridSize | null,
  xs?: GridSize | null,
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify' | null,
  mandatory?: true | null,
  //for now, its for input-adornment
  inputProps?: InputProps,
  customInput?: (props: any) => JSX.Element, 
}

export const tableKeys: TerminalTableKey[] = [
  { label: 'Ügyfél', key: 'partner' },
  { label: 'Telephely neve', key: 'partnerAddressName' },
  { label: 'Telephely címe', key: 'partnerFullAddress' },
  { label: 'Terminál típusa', key: 'terminalTypeName' },
  { label: 'Terminál gyári száma', key: 'serialNumber' },
  { label: 'Kihelyezés dátuma', key: 'outDate', },
  { label: 'Visszavétel dátuma', key: 'inDate', },
  { label: 'Manager kártya szám', key: 'managerCard'},
  { label: 'Havi díj', key: 'fee' },
  { label: 'Státusz', key: 'statusName'},
];

export const gridKeys: TerminalGridKey[] = [
  { label: 'Gyári szám', key: 'serialNumber'},
  { label: 'TID', key: 'tidString'},
  { label: 'PIN1', key: 'pin1'},
  { label: 'PIN2', key: 'pin2'},
  { label: 'PUK', key: 'puk'},
  { label: 'Manager kártya szám', key: 'managerCard'},
];

export const addTerminalOwnerGridKeys: TerminalGridKey[] = [
  { label: 'Partner neve', key: 'partnerName', inputDisabled: true },
  { label: 'Telephely megnevezése', key: 'seat', inputDisabled: true },
  { label: 'Telephely címe', key: 'fullAddress', inputDisabled: true },
  { label: 'Terminál típusa', key: 'terminalTypeName', inputDisabled: true },
];

export const returnTerminalGridKeys: TerminalGridKey[] = [
  { label: 'Partner neve', key: 'partner', inputDisabled: true },
  { label: 'Telephely megnevezése', key: 'partnerAddressName', inputDisabled: true },
  { label: 'Telephely címe', key: 'partnerFullAddress', inputDisabled: true },
  { label: 'Terminál típusa', key: 'terminalTypeName', inputDisabled: true },
];

//function for extended key (statusName)
export const getStateName = (state: string): string => {
  let result;
  switch(state){
    case 'K':
      result = 'Kihelyezett';
      break;

    case 'S':
      result = 'Szervizben';
      break;

    case 'X':
      result = 'Selejtezett';
      break;

    case 'U':
      result = 'Új';
      break;

    case 'O':
      result = 'Összerendelt';
      break;

    case 'V':
      result = 'Visszavett';
      break;

    case 'P':
      result = 'Szüneteltetett';
      break;

    default:
      result = 'Nincs megadva';
      break;
  }

  return result;
}

export type TerminalFilter = {
  name: string,
  serial: string,
  state: string,
  type: string,
}

export type TerminalState = {
  key: string,
  desc: string,
}