import { Grid, Paper, TextField, Typography, Box, Button } from "@mui/material";
import { Key, useContext, useState } from "react";
import { addTerminalOwnerGridKeys, TerminalGridKey, TerminalModel } from "../../models/TerminalModel";
import { style } from "./addTerminalOwner.style";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect } from "react";
import TerminalService from "../../services/terminals/TerminalService";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/hu";
import { TerminalRequestModel } from "../../models/TerminalRequestModel";
import ResponseModel from "../../models/ResponseModel";
import { GlobalContext, GlobalContextType } from "../../App";

// -----------------------------------------------------------------Exports---
export default function AddTerminalOwner(props: AddTerminalOwnerProps): JSX.Element {
  const [request, setRequest] = useState<TerminalRequestModel>(props.request!);
  const [selectedTerminal, setSelectedTerminal] = useState<TerminalModel | undefined>(undefined);
  const [date, setDate] = useState<Date>(new Date());
  const globalContext = useContext<GlobalContextType | null>(GlobalContext);

  useEffect(() => {
    getTerminals();
  // eslint-disable-next-line
  }, []);

  const getTerminals = async (): Promise<void> => {
    let response: ResponseModel = await TerminalService.getTerminals({tidCode: request.tidCode!}, globalContext!.logOut);

    //showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
      return;
    }
    else{
      setSelectedTerminal(
        (response.data as TerminalModel[]).find(terminal => (terminal.tidCode === request.tidCode!) && (terminal.terminalCode === request.terminalCode))
      );
    }
  }

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string): void => {
    let newRequest: TerminalRequestModel = { ...request };
    (newRequest as any)[key] = e.target.value;
    setRequest(newRequest);
  };  

  const onDatePicker = (date: Date | ((prevState: Date) => Date) | null, value: string | undefined): void => {
    setDate(date!);
  }

  const onRegisterButton = async (): Promise<void> => {
    //guards
    if(selectedTerminal === undefined){ toast.error('Nincs kiválasztva gyári szám'); return; }    
    if(moment(date) < moment().subtract(15, 'd')){ toast.error('A kiválasztott nap nem lehet korábban, mint 15 nappal ezelőtt'); return; }
    if(moment(date) > moment().add(15, 'd')){ toast.error('A kiválasztott nap nem lehet később, mint 15 nappal ezután'); return; }
    if(moment(date) < moment((request.tid as any).createdAt)){ toast.error('A kiválasztott nap nem lehet korábban, mint az összerendelés napja'); return; }

    //updating terminal state
    let response: ResponseModel = await TerminalService.terminalRelocation(selectedTerminal!.terminalCode, {
        terminalContractStart: date
      },
      globalContext!.logOut  
    );

    //showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
      return;
    }
    else{
      toast.success('Sikeres kihelyezés')
      props.getTerminalRequests();
      props.setOwnerSetterOpen(false);
    }
  }

  return(
    <Paper style={style.paper}>
      <Typography variant="h3" style={style.title}>Terminál kihelyezés</Typography>
      <Grid container spacing={4}>
        {addTerminalOwnerGridKeys.filter((gridKey: TerminalGridKey): boolean => gridKey.inputHidden !== true)
          .map((gridKey: TerminalGridKey, i: Key): JSX.Element => {
            return (
              <Grid
                item
                key={i}
                md={gridKey.md ?? 3}
                sm={gridKey.sm ?? 6}
                xs={gridKey.xs ?? 12}
              >
                <Typography
                  style={style.gridLabel}
                  align={gridKey.align ?? 'left'}
                >
                  {gridKey.label}
                </Typography>
                <TextField 
                  fullWidth 
                  disabled={gridKey.inputDisabled ?? false}
                  value={(request as any)[gridKey.key] ?? ''} 
                  variant="standard" 
                  onChange={
                    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
                      onTextChange(e, gridKey.key)
                  } 
                />
                {gridKey.mandatory &&
                  <Typography style={style.helper}>Kötelező mező*</Typography>
                }
              </Grid>
            )
          })}
          <Grid 
            item 
            xs={12}         
            sm={6}
            md={3}
          >
            <Typography style={style.gridLabel} align={'left'}>
              Terminál gyári száma
            </Typography>
            <TextField 
              fullWidth 
              disabled={true}
              value={selectedTerminal?.serialNumber} 
              variant="standard" 
            />
          </Grid>
          <Grid 
            item 
            xs={12}         
            sm={6}
            md={3}
          >
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="hu">
                <Typography style={style.gridLabel} align={'left'}>
                  Kihelyezés ideje
                </Typography>
              <MobileDatePicker
                inputFormat="YYYY-MM-DD"
                toolbarTitle="Válassz egy dátumot"
                value={date}
                onChange={onDatePicker}
                renderInput={(params) => <TextField {...params} fullWidth variant="standard"/>}
              />
            </LocalizationProvider>
          </Grid>
      </Grid>
      <Box style={style.buttonBox}>
        <Button variant="contained" color="error" onClick={() => props.setOwnerSetterOpen(false)}>
          Vissza
        </Button>
        <Button variant="contained" onClick={onRegisterButton} style={style.button}>
          Mentés
        </Button>
      </Box>
    </Paper>
  )
};

type AddTerminalOwnerProps = {
  getTerminalRequests: () => Promise<void>,
  request: TerminalRequestModel | null,
  setOwnerSetterOpen: React.Dispatch<React.SetStateAction<boolean>>,
};
