// -----------------------------------------------------------------Imports---
import {
  Key,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { style } from './AccordionTableExample.style';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../TableAccordions/TableAccordions';

import { exampleArray, ExampleGridKey, ExampleModel, ExampleTableKey, gridKeys, tableKeys } from './AccordionTableModel';

// -----------------------------------------------------------------Exports---
export default function AccordionTableExample(props: any): JSX.Element {
  const [examples] = useState<ExampleModel[]>(exampleArray);
  const [filteredExamples, setFilteredExamples] = useState<ExampleModel[]>(exampleArray);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [nameFilter, setNameFilter] = useState<string>('');
  const [expanded, setExpanded] = useState<string | false>(false);

  //render the head once
  const headMemo = useMemo(() => {
    return tableKeys.map((tableKey: ExampleTableKey, i: Key) => {
      return (
        <TableCell key={i} style={style.tableHeadCell} align={tableKey.align ?? 'left'}>{tableKey.label}</TableCell>
      )
    });
  }, []);

  //paging
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  //changing row amount
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  //accordion handling
  const accordionChange = (panel: string) => (event: any): void => {
    if (expanded === panel)
      setExpanded(false);
    else
      setExpanded(panel);
  };

  //filtering the data
  const nameFilterOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNameFilter(event.target.value);
    if (event.target.value.length > 2) {
      let newExamples: ExampleModel[] = [];
      newExamples = examples.filter((example: any) => {
        return example.firstName.toLowerCase().includes(event.target.value.toLowerCase())
      });
      setFilteredExamples(newExamples);
    }
    else if (filteredExamples !== examples) {
      setFilteredExamples(examples);
    }
  };

  // --------------------------------------------------------------Return---
  return (
    <Paper style={style.paper}>
      <Typography variant="h3" style={style.title}>Example listázása</Typography>
      <Container style={style.container}>
        <TextField
          variant="outlined"
          value={nameFilter}
          onChange={nameFilterOnChange}
          style={style.filterTextField}
          label="Cégnév"
        />
        <Button onClick={() => alert('DIY')} variant="contained" style={style.navButton}>
          Új example
        </Button>
      </Container>
      <TableContainer style={style.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {headMemo}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredExamples
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((example: any, i): JSX.Element => {
                return (
                  <>
                    <TableRow
                      key={i * 2}
                      onClick={accordionChange(example.id)}
                      style={style.dataRow}
                    >
                      {tableKeys.map((tableKey, j): JSX.Element => {
                        return (
                          <TableCell
                            align={tableKey.align ?? 'left'}
                            style={style.dataCell}
                            key={j}
                          >
                            {example[tableKey.key]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                    <TableRow key={i * 2 + 1}>
                      <TableCell style={style.accordionCell} colSpan={tableKeys.length}>
                        <Accordion expanded={expanded === example.id}>
                          <AccordionSummary />
                          <AccordionDetails style={style.accordionDetails}>
                              <Grid container spacing={4}>
                                {gridKeys.map((gridKey: ExampleGridKey, j: Key): JSX.Element => {
                                  return (
                                    <Grid key={j} item md={gridKey.md ?? 3} sm={gridKey.sm ?? 6} xs={gridKey.xs ?? 6}>
                                      <Typography key={1} style={style.gridLabel} align={gridKey.align ?? 'left'}>{gridKey.label}</Typography>
                                      <Typography key={2} align={gridKey.align ?? 'left'}>{example[gridKey.key]}</Typography>
                                    </Grid>
                                  )
                                })}
                              </Grid>
                            <Container style={style.buttonContainer}>
                              <Button variant="contained" onClick={() => alert('DIY')}>Example szerkesztése</Button>
                              <Button variant="contained" color="error" onClick={() => alert('DIY')}>Example törlése</Button>
                            </Container>
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  </>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        component="div"
        count={filteredExamples.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
