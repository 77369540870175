// -----------------------------------------------------------------Imports---
import axios, { AxiosError } from 'axios';

import {
  getAppApiRoutes,
  getAppApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import LoginService from "../login/LoginService";

// ----------------------------------------------------------------Privates---
class PartnerService {
  async getPartners(data: any = {}, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .post(
        getAppApiUrlBase() + getAppApiRoutes.partner.getPartners,
        data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token')
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.getPartners(data, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }

  async updatePartner(partner: any, id: string, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .put(
        getAppApiUrlBase() + getAppApiRoutes.partner.updatePartner.replace('{id}', id),
        {
          ...partner
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token')
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.updatePartner(partner, id, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }

  async getPartner(id: string, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .get(
        getAppApiUrlBase() + getAppApiRoutes.partner.getPartner.replace('{id}', id),        
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token')
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.getPartner(id, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }

  async deletePartner(id: number, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .delete(
        getAppApiUrlBase() + getAppApiRoutes.partner.removePartner.replace('{id}', id.toString()),
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token')
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.deletePartner(id, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }

  async addPartner(partner: any, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .post(
        getAppApiUrlBase() + getAppApiRoutes.partner.addPartner,
        partner,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token')
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.addPartner(partner, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }
}

// -----------------------------------------------------------------Exports---
const service = new PartnerService();
export default service;