import { FormControl, FormHelperText } from "@mui/material";
import { Button, Grid, MenuItem, Paper, Select, SelectChangeEvent, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Key, useContext, useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { DeliveryAddressModel } from "../../models/DeliveryAddressModel";
import { PartnerModel } from "../../models/PartnerModel";
import { TerminalTypeModel } from "../../models/TerminalTypeModel";
import PartnerService from "../../services/partners/PartnerService";
import TerminalTypeService from "../../services/terminalTypes/TerminalTypeService";
import TerminalRequestService from "../../services/terminalRequests/TerminalRequestService";
import { style } from './createTerminalRequest.style';
import { TerminalModel } from "../../models/TerminalModel";
import { doneChecker, getLongAddress } from "../../utils/Utils";
import ResponseModel from "../../models/ResponseModel";
import { BooleanDictionary } from "../../GlobalTypes";
import LoadingDialog from "../../components/LoadingDialog/LoadingDialog";
import { GlobalContext, GlobalContextType } from "../../App";

export default function CreateTerminalRequest(props: CreateTerminalRequestProps): JSX.Element {
  const [swapTerminal,] = useState<TerminalModel | undefined>(props.swapTerminal);
  const [terminalTypes, setTerminalTypes] = useState<TerminalTypeModel[]>([]);
  const [partners, setPartners] = useState<PartnerModel[]>([]);
  const [deliveryAddresses, setDeliveryAddresses] = useState<DeliveryAddressModel[]>([]);
  const [amount, setAmount] = useState<string>('0');
  const [selectedTerminalType, setSelectedTerminalType] = useState<TerminalTypeModel | undefined>(undefined);
  const [selectedPartner, setSelectedPartner] = useState<PartnerModel | undefined>(undefined);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState<DeliveryAddressModel | undefined>(undefined);
  const [done, setDone] = useState<CreateTerminalRequestDone>({partners: false, terminalTypes: false});
  const globalContext = useContext<GlobalContextType | null>(GlobalContext);

  //getting all the terminal types
  const getTerminalTypes = async (): Promise<void> => {
    let response: ResponseModel = await TerminalTypeService.getTerminalTypes(globalContext!.logOut);

    //showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
      return;
    }

    setTerminalTypes(response.data as TerminalTypeModel[]);

    if(!done.terminalTypes)
      setDone(done => {done.terminalTypes = true; return done});
  }

  //getting all the partners
  const getPartners = async (): Promise<void> => {
    let response: ResponseModel = await PartnerService.getPartners({}, globalContext?.logOut!);

    //showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
      return;
    }

    setPartners(response.data as PartnerModel[]);

    if(!done.partners)
      setDone(done => {done.partners = true; return done});
  }

  //on partner update, set up the new addresses
  const getDeliveryAddresses = (): void => {
    setDeliveryAddresses(
      partners.find(
        (partner): boolean => partner.partnerCode === selectedPartner?.partnerCode
      )
      ?.deliveryAddresses as DeliveryAddressModel[]
    )
  }

  //partner select handler
  const onPartnerSelect = (e: SelectChangeEvent) => {
    setSelectedPartner(partners.find(
      (partner): boolean => partner.partnerCode === parseInt(e.target.value)
    ) as PartnerModel);
    setSelectedDeliveryAddress(undefined);
  }

  //address select handler
  const onDeliveryAddressSelect = (e: SelectChangeEvent) => {
    setSelectedDeliveryAddress(deliveryAddresses.find(
      (address): boolean => address.deliveryAddressCode === parseInt(e.target.value)
    ) as DeliveryAddressModel);
  }

  //terminal select handler
  const onTerminalTypeSelect = (e: SelectChangeEvent) => {
    setSelectedTerminalType(terminalTypes.find(
      (terminal): boolean => terminal.terminalTypeCode === parseInt(e.target.value)
    ) as TerminalTypeModel);
  }

  //amount change handler
  const onAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  //event for registration button
  const onRegisterButton = async (): Promise<void> => {
    // guards
    if(swapTerminal === undefined){
      if(selectedDeliveryAddress === undefined){ toast.error('Nem adtál meg szállítási címet'); return; }
      if(selectedTerminalType === undefined){ toast.error('Nem adtál meg típust'); return; }
      if(isNaN(parseInt(amount)) || parseInt(amount).toString() !== amount){ toast.error('Nem valós egész mennyiséget adtál meg'); return; }
      if(parseInt(amount) < 1){ toast.error('Pozitív kell legyen a mennyiség'); return; }
    }

    // data
    let data: any;
    // swapping request
    if(swapTerminal !== undefined){
      data = {
        deliveryAddressCode: swapTerminal.tid.deliveryAddressCode,
        terminalTypeCode: swapTerminal.tid.terminalTypeId,
        number: 1,
        tidCode: swapTerminal.tidCode
      };
    }
    // adding new requests
    else{
      data = {
        deliveryAddressCode: selectedDeliveryAddress?.deliveryAddressCode,
        terminalTypeCode: selectedTerminalType?.terminalTypeCode,
        number: amount
      };
    }

    // send the request
    let response: ResponseModel = await TerminalRequestService.addTerminalRequest(data, globalContext!.logOut);

    // showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
      return;
    }
    else{
      toast.success('Sikeres igény leadás');
      props.setDialogOpen(false);
      if(props.getTerminalRequests)
        props.getTerminalRequests();
    }
  }

  //get data on load
  useEffect((): void => {
    getTerminalTypes();
    getPartners();
  // eslint-disable-next-line
  }, []);

  //reload the addresses when the partner changes
  useEffect((): void => {
    if(selectedPartner !== undefined){
      getDeliveryAddresses();
    }
    else{
      setSelectedDeliveryAddress(undefined);
      setDeliveryAddresses([]);
    }
  // eslint-disable-next-line
  }, [selectedPartner]);

  // blocking with a dialog while getting all the data
  if(!doneChecker(done)){
    return <LoadingDialog/>
  }

  return(
    <Paper style={style.paper} >
      <Typography variant="h3" align="center" marginBottom={8}>{props.title}</Typography>
      <Grid container spacing={4}>

        {/* Partner select */}
        <Grid item sm={6} md={3} xs={12}>
          <Typography style={style.label}>Ügyfél</Typography>
          <Select
            fullWidth
            value={swapTerminal
              // swap terminals partner
              ? swapTerminal.tid.deliveryAddress.partnerCode
              // selected partner
              : selectedPartner?.partnerCode.toString() ?? "-1" //must be string !?
            }
            disabled={swapTerminal !== undefined}
            onChange={onPartnerSelect}
          >
            {/* Give a placeholder item */}
            <MenuItem value={"-1"} key={-1}>
              Nincs kiválasztva
            </MenuItem>
            {partners.map((partner: PartnerModel, i: Key) => {
              return(
                <MenuItem value={partner.partnerCode} key={i}>
                  {partner.name}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>

        {/* Delivery Address select */}
        <Grid item sm={6} md={3} xs={12}>
          <Typography style={style.label}>Telephely</Typography>
          <FormControl style={style.formControl}>
            <Select
              fullWidth
              defaultValue={"-1"} //must be string !?
              onChange={onDeliveryAddressSelect}
              // make sure it goes back to default value
              value={swapTerminal
                // swap terminal address
                ? swapTerminal.tid.deliveryAddressCode!
                // select address
                : selectedDeliveryAddress?.deliveryAddressCode.toString() ?? '-1'
              }
              disabled={swapTerminal !== undefined}
            >
              {/* Give a placeholder item */}
              <MenuItem value={"-1"} key={-1}>
                Nincs kiválasztva
              </MenuItem>
              {swapTerminal !== undefined &&
                <MenuItem value={swapTerminal.tid.deliveryAddressCode}>
                  {getLongAddress(swapTerminal.tid.deliveryAddress, true)}
                </MenuItem>
              }
              {deliveryAddresses.map((address: DeliveryAddressModel, i: Key) => {
                return(
                  <MenuItem value={address.deliveryAddressCode} key={i}>
                    {getLongAddress(address, true)}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>Először válassz ügyfélt</FormHelperText>
          </FormControl>
        </Grid>
        
        {/* Terminal type */}
        <Grid item sm={6} md={3} xs={12}>
          <Typography style={style.label}>Terminál típusa</Typography>
          <Select
            fullWidth
            disabled={swapTerminal !== undefined}
            value={swapTerminal
              // swap terminal type
              ? swapTerminal.tid.terminalTypeId!
              // select type
              : selectedTerminalType?.terminalTypeCode.toString() ?? "-1"
            }
            onChange={onTerminalTypeSelect}
          >
            {/* Give a placeholder item */}
            <MenuItem value={"-1"} key={-1}>
              Nincs kiválasztva
            </MenuItem>
            {terminalTypes.map((type: TerminalTypeModel, i: Key) => {
              return(
                <MenuItem value={type.terminalTypeCode} key={i}>
                  {type.name}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>

        {/* Number of requests */}
        <Grid item sm={6} md={3} xs={12}>          
          <Typography style={style.label}>Igényelt terminál darabszáma</Typography>
          <TextField
            fullWidth
            type="number"
            disabled={swapTerminal !== undefined}
            value={
              swapTerminal
              // 1 for swap
              ? 1
              // select otherwise
              : amount
            }
            onChange={onAmountChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>

      {/* Buttons */}
      <Box style={style.buttonBox}>
        <Button variant="contained" color="error" onClick={() => props.setDialogOpen(false)}>
          Vissza
        </Button>
        <Button variant="contained" onClick={onRegisterButton} style={style.button}>
          Mentés
        </Button>
      </Box>
    </Paper>
  )
}

type CreateTerminalRequestProps = {
  setDialogOpen: (value: boolean) => void,
  // from terminal request list
  getTerminalRequests?: () => void,
  // from swap at terminals
  title?: string,
  swapTerminal?: TerminalModel,
};

type CreateTerminalRequestDone = BooleanDictionary & {
  partners: boolean,
  terminalTypes: boolean,
};
