import { CustomStyle } from "../../GlobalTypes";

// -----------------------------------------------------------------Exports---
export const style: CustomStyle = {
  paper: {
    margin: 32,
    padding: 16,
    maxWidth: 'none',
  },
  title: {
    marginBottom: 64,
  },
  gridLabel: {
    fontWeight: 600,
    fontSize: 18,
  },
  saveButton: {
    height: 56,
    margin: 16,
  },
  divider:{
    margin: 32
  },
  helper: {
    marginLeft: 8,
    fontSize: 12,
    marginTop: 4,
  }
}
