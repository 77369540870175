// -----------------------------------------------------------------Imports---
import { CustomStyle } from '../../GlobalTypes';

// -----------------------------------------------------------------Exports---
export const LoginPageStyles: CustomStyle = {
  box: {
    backgroundColor: 'rgb(21, 41, 52)', 
    height: '100vh', 
    display: 'flex', 
    flexDirection: 'column'
  },
  headerBox: {
    borderBottom: '5px red solid', 
    display: 'flex', 
    justifyContent: 'space-around', 
    alignItems: 'center',
    padding: 8,
    height: 75,
  },
  headerImage: {
    height: 48, 
    overflow: 'hidden', 
    objectFit: 'contain',
  },
  headerText: {
    fontSize: 32, 
    color: 'rgb(195, 195, 173)',
  },
  contentBox: {
    height: '100%', 
    display: 'flex',
  },
  formGrid: {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center', 
    gap: 64,
  },
  inputStyle: {
    padding: 12, 
    color: 'rgb(195, 195, 195)',
  },
  textfieldStyle: {
    maxWidth: 240, 
    border: '5px rgb(195, 195, 195) solid', 
    color:'rbg(195, 195, 195)',
  },
  buttonStyle: {
    maxWidth: 240, 
    backgroundColor: 'rgb(204, 46, 46)', 
    fontWeight: 'bold', 
    textTransform: 'none', 
    padding: 20,
  },
  imageGrid: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
  },
  coverImage: {
    height: '70vh', 
    overflow: 'hidden', 
    objectFit: 'contain',
  }
}
