// -----------------------------------------------------------------Imports---
import {
  Key,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { toast } from 'react-toastify';

import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { style } from './AddPartner.style';

import {
  gridKeys,
  gridKeys2,
  PartnerGridKey,
  PartnerModel,
} from '../../models/PartnerModel';

import PartnerService from '../../services/partners/PartnerService';
import { Divider } from '@mui/material';
import { getLongAddress } from '../../utils/Utils';
import ResponseModel from '../../models/ResponseModel';
import { GlobalContext, GlobalContextType } from '../../App';

// -----------------------------------------------------------------Exports---
export default function AddPartner(props: AddPartnerProps): JSX.Element {
  const [partner, setPartner] = useState<PartnerModel>({} as PartnerModel);
  const [id] = useState<number | undefined>(props.id);
  const globalContext = useContext<GlobalContextType | null>(GlobalContext);

  //get a partner to update, if id is available
  const getPartner = useCallback(async (id: number): Promise<void> => {
    let response: ResponseModel = await PartnerService.getPartner(id.toString(), globalContext!.logOut);
    
    if (response.error !== null) {
      toast.error(response.error?.message);
    }
    else {
      let data: PartnerModel = response.data as PartnerModel;
      setPartner({
        ...response.data,
        //filter the +36 and 06 out
        telephone: 
          data.telephone?.startsWith('+36')
          ? data.telephone.substring(3, data.telephone.length).toString()
          : data.telephone?.startsWith('06').toString()
            ? data.telephone.substring(2, data.telephone.length).toString()
            : data.telephone?.toString()
      } as PartnerModel);
    }
  }, [globalContext]);

  //saving the new partner
  const savePartner = async (): Promise<void> => {
    //guards
    if (partner.name === undefined || partner.name!.length < 3) { toast.error('A név legalább 3 karakter kell legyen'); return; }
    // if (partner.bankAccountNumber === undefined) { toast.error('Bankszámlaszámot meg kell adni'); return; }
    if (partner.taxNumber === undefined) { toast.error('Az adószámot meg kell adni'); return; }
    if (partner.representative === undefined) { toast.error('Képviselőt meg kell adni'); return; }
    if (partner.telephone === undefined) { toast.error('Telefon számot meg kell adni'); return; }
    if (partner.email === undefined || partner.email!.length < 6) { toast.error('Az email legalább 6 karakter kell legyen'); return; }
    if (partner.postalCode === undefined) { toast.error('Irányítószámot meg kell adni'); return; }
    if (partner.city === undefined) { toast.error('Várost meg kell adni'); return; }
    if (partner.streetName === undefined) { toast.error('Közterület nevét meg kell adni'); return; }
    if (partner.streetType === undefined) { toast.error('Közterület típusát meg kell adni'); return; }
    if (partner.houseNumber === undefined) { toast.error('Házszámot meg kell adni'); return; }
    if (partner.notificationAddress === undefined) { toast.error('Értesítési cím nem került kitöltésre'); return; }
    if (partner.representative!.length <= 3) {toast.error('A képviselő hossza nagyobb kell legyen mint 3'); return; }
    if (partner.telephone!.length <= 3) {toast.error('A telefon hossza nagyobb kell legyen mint 3'); return; }
    if (partner.email!.length <= 3) {toast.error('Az email hossza nagyobb kell legyen mint 3'); return; }
    if (partner.postalCode!.length <= 3) {toast.error('Az irányítószám hossza nagyobb kell legyen mint 3'); return; }
    if (partner.city!.length <= 0) {toast.error('A városnak meg kell adni valamit'); return; }
    if (partner.streetName!.length <= 0) {toast.error('A közterület neve hossza nagyobb kell legyen mint 0'); return; }
    if (partner.streetType!.length <= 0) {toast.error('A közterület hossza nagyobb kell legyen mint 0'); return; }
    if (partner.houseNumber!.length <= 0) {toast.error('A házszám hossza nagyobb kell legyen mint 0'); return; }
    if (partner.notificationAddress!.length <= 8) { toast.error('Értesítési cím hossza nagyobb kell legyen mint 9'); return; }
    let emailRegex: RegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    if (!emailRegex.test(partner.email!)) { toast.error('Nem megfelelő az email formátum'); return; }
    let VATRegex: RegExp = /^(\d{7})(\d)-([1-5])-(0[2-9]|[13][0-9]|2[02-9]|4[0-4]|51)$/;
    let matches: RegExpMatchArray = partner.taxNumber!.match(VATRegex)!;
    if (!matches) { toast.error('Nem megfelelő az adószám formátuma (12345678-0-00)'); return; }
    else{
      //magic hun taxnumber validator
      let mul: number[] = [9, 7, 3, 1, 9, 7, 3];
      let base: string[] = matches[1].toString().split("");
      let check: number = parseInt(matches[2].toString());
      let sum: number = 0;
      for (let i: number = 0; i < 7; i++) { sum += parseInt(base[i]) * mul[i]; }
      let last: number = sum % 10;
      if (last > 0) { last = 10 - last; }
      if(last !== check){
        toast.error('Nem létező adószámot adott meg');
        return;
      }
    }

    //sending the request
    let response: ResponseModel;
    if(id === undefined)
      response = await PartnerService.addPartner(partner, globalContext!.logOut);
    else
      response = await PartnerService.updatePartner(partner, id.toString(), globalContext!.logOut);

    //showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
      toast.error(response.error?.fields![0].message);
    }
    else {
      if(id === undefined)
        toast.success('Sikeres partner rögzítés');
      else
        toast.success('Sikeres partner frissítés');
      props.setSetterOpen(false);
      props.getPartners();
    }
  }

  //updating the new partner state
  const onTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string): void => {
    let newPartner: PartnerModel = { ...partner };
    (newPartner as any)[key] = e.target.value;
    setPartner(newPartner);
  };  

  const loadDefaultNotificationAddress = (): void => {
    let newPartner: PartnerModel = { ...partner };
    newPartner.notificationAddress = getLongAddress(newPartner);
    setPartner(newPartner);
  }

  //devnote: somehow this is enough
  useEffect(() => {
    if(id !== undefined){
      getPartner(id);
    }
  }, [id, getPartner]);

  // --------------------------------------------------------------Return---
  return (
    <Paper style={style.paper}>
      <Typography variant="h3" style={style.title}>        
        {id === undefined && 
          'Ügyfél hozzáadása'
        }
        {id !== undefined &&
          'Ügyfél frissítése'
        }
      </Typography>

      {/* Company data */}
      <Divider style={style.divider} textAlign="left">Céges adatok</Divider>
      <Grid container spacing={4}>
        {gridKeys.filter((gridKey: PartnerGridKey): boolean => gridKey.inputHidden !== true)
          .map((gridKey: PartnerGridKey, i: Key): JSX.Element => {
            return (
              <Grid
                item
                key={i}
                md={gridKey.md ?? 3}
                sm={gridKey.sm ?? 6}
                xs={gridKey.xs ?? 12}
              >
                <Typography
                  style={style.gridLabel}
                  align={gridKey.align ?? 'left'}
                >
                  {gridKey.label}
                </Typography>
                {!gridKey.customInput &&
                  <TextField 
                    fullWidth 
                    value={(partner as any)[gridKey.key] ?? ''} 
                    variant="standard" 
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        onTextChange(e, gridKey.key)
                    } 
                    InputProps={gridKey.InputProps}
                    inputProps={gridKey.inputProps}
                  />
                }
                {gridKey.customInput &&
                  <gridKey.customInput 
                    value={(partner as any)[gridKey.key] ?? ''}
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
                        onTextChange(e, gridKey.key)
                    }
                  />
                }
                {gridKey.mandatory &&
                  <Typography style={style.helper}>Kötelező mező*</Typography>
                }
              </Grid>
            )
          })
        }
      </Grid>

      {/* LocationData */}
      <Divider style={style.divider} textAlign="left">Cím adatok</Divider>
      <Grid container spacing={4}>
        {gridKeys2.filter((gridKey: PartnerGridKey): boolean => gridKey.inputHidden !== true)
          .map((gridKey: PartnerGridKey, i: Key): JSX.Element => {
            return (
              <Grid
                item
                key={i}
                md={gridKey.md ?? 3}
                sm={gridKey.sm ?? 6}
                xs={gridKey.xs ?? 12}
              >
                <Typography
                  style={style.gridLabel}
                  align={gridKey.align ?? 'left'}
                >
                  {gridKey.label}
                </Typography>
                <TextField fullWidth value={(partner as any)[gridKey.key] ?? ''} variant="standard" onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onTextChange(e, gridKey.key)} />
                {gridKey.mandatory &&
                  <Typography style={style.helper}>Kötelező mező*</Typography>
                }
              </Grid>
            )
          })}
      </Grid>

      {/* Footer buttons */}
      <Button color="success" onClick={loadDefaultNotificationAddress} variant="contained" style={style.saveButton}>
        Székhely címének másolása
      </Button>
      <Button onClick={savePartner} variant="contained" style={style.saveButton}>
        {id === undefined && 
          'Rögzítés'
        }
        {id !== undefined &&
          'Frissítés'
        }
      </Button>
      <Button color="error" onClick={() => props.setSetterOpen(false)} variant="contained" style={style.saveButton}>
        Mégse
      </Button>
    </Paper>
  );
}

type AddPartnerProps = {
  id: number | undefined,
  setSetterOpen: React.Dispatch<React.SetStateAction<boolean>>,
  getPartners: () => Promise<void>,
};
