// -----------------------------------------------------------------Imports---
import axios, { AxiosError } from 'axios';

import {
  getAppApiRoutes,
  getAppApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';

// ----------------------------------------------------------------Privates---
class LoginService {
  async onLogin(username: any, password: any): Promise<ResponseModel> {
    let responseData: ResponseModel = {
      status: 400,
      data: {},
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .post(
        getAppApiUrlBase() + getAppApiRoutes.login.user,
        {
          username: username,
          password: password
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(function (error: AxiosError): void {
        if (error.response!.status === 401) {
          responseData = (error.response! as any);
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }

  async onRefresh(): Promise<ResponseModel> {
    let responseData: ResponseModel = {
      status: 400,
      data: {},
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .post(
        getAppApiUrlBase() + getAppApiRoutes.login.userTokenRefresh,
        {},
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token'),
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
        localStorage.setItem('micra_token', 'Bearer ' + response.data.data.token);
      })
      .catch(function (error: AxiosError): void {
        if (error.response!.status === 401) {
          responseData = (error.response! as any);
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }
}

// -----------------------------------------------------------------Exports---
const service = new LoginService();
export default service;