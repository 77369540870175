import { Typography, Container, Button, TextField, TextFieldProps, Switch, Stack, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { style } from './returnTerminal.style';
import { useContext, useState, Key, useEffect } from "react";
import { returnTerminalGridKeys, TerminalGridKey, TerminalModel } from "../../models/TerminalModel";
import { toast } from "react-toastify";
import TerminalService from '../../services/terminals/TerminalService';
import moment from "moment";
import "moment/locale/hu";
import { Grid, Box, Paper } from "@mui/material";
import Radio from '@mui/material/Radio';
import TerminalAccessoryService from '../../services/terminalAccessories/TerminalAccessoryService';
import TerminalErrorDescriptionService from '../../services/terminalErrorDescription/TerminalErrorDescriptionService';
import { AccessoryStateModel, TerminalAccessoryModel } from "../../models/TerminalAccessoryModel";
import { TerminalErrorDescriptionModel } from "../../models/TerminalErrorDescriptionModel";
import ResponseModel from "../../models/ResponseModel";
import { GlobalContext, GlobalContextType } from "../../App";

export default function ReturnTerminal(props: ReturnTerminalProps): JSX.Element {
  const [returningTerminal, ] = useState<TerminalModel>(props.returningTerminal!);
  const [date, setDate] = useState<Date>(new Date());
  const [isContractTermination, setContractTermination] = useState<boolean>(
    props.returningTerminal!.isReplacementNeed!
  );
  const [isContractTerminationEnabled,] = useState<boolean>(
    props.returningTerminal!.isReplacementNeed!
  );
  const [possibleErrors, setPossibleErrors] = useState<TerminalErrorDescriptionModel[]>([]);
  const [selectedError, setSelectedError] = useState<TerminalErrorDescriptionModel | null>(null);
  const [comment, setComment] = useState<string>('');
  const [terminalAccessories, setTerminalAccessories] = useState<AccessoryStateModel[]>([]);
  const globalContext = useContext<GlobalContextType | null>(GlobalContext);

  // getting data
  useEffect(() => {
    getAccessories();
    getErrors();
  //eslint-disable-next-line
  }, []);

  // getting possible errors
  const getErrors = async (): Promise<void> => {
    let response: ResponseModel = await TerminalErrorDescriptionService.getTerminalErrorDescriptions({ active: "I" }, globalContext!.logOut);
    
    // showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
      return;
    }
    else{
      setPossibleErrors(response.data as TerminalErrorDescriptionModel[]);
    }
  };
  
  // getting accessories
  const getAccessories = async (): Promise<void> => {
    let response: ResponseModel = await TerminalAccessoryService.getTerminalAccessories({terminalTypeCode: returningTerminal.terminalTypeCode}, globalContext!.logOut);
    
    // showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
      return;
    }
    else{
      setTerminalAccessories(
        (response.data as TerminalAccessoryModel[]).map((accessory: TerminalAccessoryModel) => {
          return {...accessory, state: 'N'} as AccessoryStateModel;
        })
      );
    }
  };

  // on date picker
  const onDatePicker = (date: Date | ((prevState: Date) => Date) | null, value: string | undefined): void => {
    setDate(date!);
  }

  // on returning the terminal
  const onReturn = async (): Promise<void> => {
    // guards
    if(moment(date) < moment().subtract(15, 'd')){ toast.error('A kiválasztott nap nem lehet korábban, mint 15 nappal ezelőtt'); return; }
    if(moment(date) > moment().add(15, 'd')){ toast.error('A kiválasztott nap nem lehet később, mint 15 nappal ezután'); return; }
    if(moment(date) < moment(returningTerminal.date)){ toast.error('A kiválasztott nap nem lehet korábban, mint a kihelyezés napja'); return; }
    if(!selectedError){ toast.error('Hibajelenséget kell választani'); return;}

    let response: ResponseModel = await TerminalService.terminalTakeBack(returningTerminal!.terminalCode, {
        isTerminalContractTermination: isContractTermination,
        terminalContractEnd: date,
        terminalService: {
          terminalErrorDescriptionCode: selectedError ? selectedError!.errorCode : null,
          terminalCode: -1,
          errorDescription: comment,
        },
        terminalServiceAccessories: terminalAccessories
          .filter((accessory: AccessoryStateModel): boolean => accessory.state === 'I')
          .map((accessory: AccessoryStateModel): AccessoryStateModel => {
            return {terminalServiceCode: -1, terminalAccessoryCode: accessory.terminalAccessoryCode, state: 'I'} as AccessoryStateModel;
          })
      },
      globalContext!.logOut,
    );

    if (response.error !== null) {
      toast.error(response.error?.message);
    }
    else {
      toast.success('Sikeres visszavétel');
      props.getTerminals();
      props.setReturningOpen(false);
    }
  };

  // on error select
  const onErrorSelect = (e: SelectChangeEvent) => {
    setSelectedError(possibleErrors.find(
      (error: TerminalErrorDescriptionModel): boolean => error.errorCode === parseInt(e.target.value)
    )!);
  }

  // on accessory change
  const onAccessoryChange = (e: React.ChangeEvent<HTMLInputElement>, accessory: TerminalAccessoryModel) => {
    setTerminalAccessories((terminalAccessories: AccessoryStateModel[]): AccessoryStateModel[] => {
      return terminalAccessories.map((terminalAccessory: AccessoryStateModel) => {
        return terminalAccessory.terminalAccessoryCode !== accessory.terminalAccessoryCode 
        ? terminalAccessory 
        : {...terminalAccessory, state: e.target.checked ? 'I' : 'N'};
      });
    });
  };

  return(
    <Paper style={style.paper}>
      <Typography variant="h3" style={style.title}>
        Biztosan vissza akarod venni a terminált?
      </Typography>
      <Grid container spacing={4}>
        {returnTerminalGridKeys.filter((gridKey: TerminalGridKey): boolean => gridKey.inputHidden !== true)
          .map((gridKey: TerminalGridKey, i: Key): JSX.Element => {
            return (
              <Grid
                item
                key={i}
                md={gridKey.md ?? 3}
                sm={gridKey.sm ?? 6}
                xs={gridKey.xs ?? 12}
              >
                <Typography
                  style={style.gridLabel}
                  align={gridKey.align ?? 'left'}
                >
                  {gridKey.label}
                </Typography>
                <TextField 
                  fullWidth 
                  disabled={gridKey.inputDisabled ?? false}
                  value={(returningTerminal as any)[gridKey.key] ?? ''} 
                  variant="standard" 
                />
                {gridKey.mandatory &&
                  <Typography style={style.helper}>Kötelező mező*</Typography>
                }
              </Grid>
            )
        })}
        <Grid 
          item 
          xs={12}         
          sm={6}
          md={3}
        >
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="hu">
            <Typography style={style.gridLabel} align={'left'}>
              Visszavétel ideje
            </Typography>
            <MobileDatePicker
              inputFormat="YYYY-MM-DD"
              toolbarTitle="Válassz egy dátumot"
              value={date}
              onChange={onDatePicker}
              renderInput={(params: TextFieldProps) => <TextField {...params} fullWidth variant="standard"/>}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          style={style.switchGrid}
        >
          <Typography style={style.gridLabel} align={'center'}>
            Visszavétel oka*
          </Typography>
          <Stack
            direction={'row'}
            alignItems={'center'}
          >
            <Radio
              checked={isContractTermination}
              onChange={() => {
                setContractTermination(true);
                setSelectedError(possibleErrors.find((error) => error.errorCode === 1)!);
              }}
              disabled={!isContractTerminationEnabled}
              />
            <Typography>Szerződés felmondása</Typography>
            
            <Radio
              checked={!isContractTermination}
              onChange={() => setContractTermination(false)}
              disabled={!isContractTerminationEnabled}
            />
            <Typography>Meghibásodás</Typography>
          </Stack>
          <Typography fontSize={10}>
            *Ha nincs a terminálon meghibásodás, akkor a szerződés felmondása esetén 
            az újrakulcsolás hibajelenséget kell kiválasztani.
          </Typography>
        </Grid>
        <Box width='100%'/>
        <Grid 
          item 
          xs={12}         
          sm={6}
          md={3}
        >
          <Typography style={style.gridLabel} align={'left'}>
            Hibajelenség leírása
          </Typography>
          <Select
            variant="standard"
            fullWidth
            value={selectedError?.errorCode.toString() ?? "-1"} //must be string !?
            onChange={onErrorSelect}
          >
            {/* Give a placeholder item */}
            <MenuItem value={"-1"} key={-1}>
              Nincs kiválasztva
            </MenuItem>
            {possibleErrors.map((error: TerminalErrorDescriptionModel, i: Key) => {
              return(
                <MenuItem value={error.errorCode} key={i}>
                  {error.name ?? ''}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          style={style.switchGrid}
        >
          <Typography style={style.gridLabel} align={'center'}>
            Megjegyzés hozzáadása
          </Typography>
          <TextField 
            fullWidth 
            value={comment}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)} 
            variant="standard" 
          />
        </Grid>
      </Grid>

      {/* Accessories */}
      <Typography variant="h3" style={style.subTitle}>
        Tartozékok
      </Typography>
      <Grid container spacing={4}>
        {terminalAccessories.map((accessory: AccessoryStateModel, i: Key) => {
          return(
            <Grid 
              item 
              xs={12}         
              sm={12}
              md={6}
              key={i}
              style={style.switchGrid}
            >
              <Typography style={style.gridLabel} align={'center'}>
                Meg van? ({accessory.name}) {accessory.unitPrice}{'\xa0'}Ft
              </Typography>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography>Nem</Typography>
                <Switch
                  checked={accessory.state === 'I'}
                  onChange={(e) => onAccessoryChange(e, accessory)}
                  />
                <Typography>Igen</Typography>
              </Stack>
            </Grid>
          )
        })}
      </Grid>
      <Container style={style.buttonContainer}>
        <Button variant="contained" onClick={() => props.setReturningOpen(false)}>Mégse</Button>
        <Button variant="contained" color="error" onClick={onReturn}>Visszavétel</Button>                   
      </Container>
    </Paper>
  );
}

type ReturnTerminalProps = {
  returningTerminal: TerminalModel | undefined,
  setReturningOpen: React.Dispatch<React.SetStateAction<boolean>>,
  getTerminals: () => Promise<void>,
}
