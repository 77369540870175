import axios, { AxiosError } from "axios";
import { getAppApiRoutes, getAppApiUrlBase } from "../../Global";
import ResponseModel from "../../models/ResponseModel";
import LoginService from "../login/LoginService";

class DeliveryAddressService {
  async deleteDeliveryAddress(id: number, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .delete(
        getAppApiUrlBase() + getAppApiRoutes.deliveryAddress.removeDeliveryAddress.replace('{id}', id.toString()),
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token')
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.deleteDeliveryAddress(id, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }

  async updateDeliveryAddress(deliveryAddress: any, id: string, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .put(
        getAppApiUrlBase() + getAppApiRoutes.deliveryAddress.updateDeliveryAddress.replace('{id}', id),
        {
          ...deliveryAddress
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token'),
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.updateDeliveryAddress(deliveryAddress, id, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }

  async addDeliveryAddress(deliveryAddress: any, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .post(
        getAppApiUrlBase() + getAppApiRoutes.deliveryAddress.addDeliveryAddress,
        deliveryAddress,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token')
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.addDeliveryAddress(deliveryAddress, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }

  async getDeliveryAddress(id: string, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .get(
        getAppApiUrlBase() + getAppApiRoutes.deliveryAddress.getDeliveryAddress.replace('{id}', id),        
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token')
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.getDeliveryAddress(id, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }
}

const service = new DeliveryAddressService();
export default service;