import { Box, Button, TextField, Typography, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import { getAppRoutes } from '../../Global';
import LoginService from '../../services/login/LoginService';
import ResponseModel from '../../models/ResponseModel';
import { LoginPageStyles } from './LoginPage.style';
import Q92 from '../../images/Q92.png';
import MicraLogo from '../../images/micra.png';
import HBLogo from '../../images/hardbit_feher.png';

// -----------------------------------------------------------------Exports---
export default function LoginPage(props: LoginPageProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  //go home if logged in
  useEffect(() => {
    if (props.loggedInState) {
      navigate(getAppRoutes.home);
    }
    // eslint-disable-next-line
  }, []);

  //handle login event
  const handleSubmit = async () => {
    let response: ResponseModel = await LoginService.onLogin(username.toUpperCase(), password);

    if (response.status === 200) {
      localStorage.setItem('micra_token', 'Bearer ' + (response.data as any).token);
      props.setLoggedInState(true);
      props.setLoggedInUser((jwt_decode((response.data as any).token) as any).sub ?? '')
    }
    else {
      toast.error('Sikertelen bejelentkezés');
    }
  };

  // --------------------------------------------------------------Return---
  return (
    <Box style={LoginPageStyles.box}>
      <Box style={LoginPageStyles.headerBox}>
        <img src={MicraLogo} alt="Cég logó" style={LoginPageStyles.headerImage}/>
        <Typography style={LoginPageStyles.headerText}>Terminál</Typography>
        <img src={HBLogo} alt="Fejlesztő logó" style={LoginPageStyles.headerImage}/>
      </Box>
      <Box style={LoginPageStyles.contentBox}>
        <Grid container>
          <Grid item sm={6} xs={12} style={LoginPageStyles.formGrid}>
            <TextField
              autoFocus
              variant='standard'
              fullWidth
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Felhasználónév"
              inputProps={{style:LoginPageStyles.inputStyle}}
              style={LoginPageStyles.textfieldStyle}
            />
            <TextField
              variant='standard'
              type="password"
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Jelszó"
              inputProps={{style:LoginPageStyles.inputStyle}}
              style={LoginPageStyles.textfieldStyle}
            />

            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              style={LoginPageStyles.buttonStyle}
            >
              Bejelentkezés
            </Button>
          </Grid>
          <Grid item sm={6} xs={0} style={LoginPageStyles.imageGrid}>
            <Box display={{xs: 'none', sm: 'flex'}} overflow='hidden'>
              <img src={Q92} alt="Q92 terminál" style={LoginPageStyles.coverImage}/>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

type LoginPageProps = {
  loggedInState: boolean,
  setLoggedInUser: React.Dispatch<React.SetStateAction<string>>,
  setLoggedInState: React.Dispatch<React.SetStateAction<boolean>>,
};
