// -----------------------------------------------------------------Imports---
import { Theme } from '@emotion/react';
import { createTheme } from '@mui/material';
import { drawerWidth } from './components/drawer/Drawer.style';

// -----------------------------------------------------------------Exports---
export const GlobalLightTheme: Theme = createTheme({
  palette: {
    mode: 'light',
    background:{
      default: '#f5f7fa'
    }
  },
});

export const GlobalDarkTheme: Theme = createTheme({
  palette: {
    mode: 'dark',
    background:{
    }
  },
});

export const styles: any = {
  loggedInBigBox: {
    maxWidth: 'none', 
    paddingRight: 0, 
    paddingLeft: 64, 
    paddingTop: 64,
    transition: 'padding 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
  },
  loggedInSmallBox:{
    maxWidth: 'none', 
    paddingRight: 0, 
    paddingLeft: drawerWidth, 
    paddingTop: 64,
    transition: 'padding 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
  },
  //y, keep this empty
  loggedOutBox: {},
};
