import { DeliveryAddressModel } from "./DeliveryAddressModel";
import { TerminalContractModel } from "./TerminalContractModal";
import { TerminalTypeModel } from "./TerminalTypeModel";

export type TerminalRequestModel = {
  terminalNeedCode: number,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: string | null,
  createdByUser?: any,
  updatedBy?: string | null,
  updateByUser?: any,
  serviceCode?: string | any,
  service?: any,
  deliveryAddressCode: number,
  deliveryAddress: DeliveryAddressModel,
  status?: string | null,
  terminalTypeCode: number,
  terminalType: TerminalTypeModel,
  terminalId?: number | null,
  terminalContract: TerminalContractModel,
  tid?: object | null,
  tidCode?: number | null,
  //casted
  partnerName?: string | null,
  seat?: string | null,
  fullAddress?: string | null,
  terminalTypeName?: string | null,
  createdAtDate?: string | null,
  terminalCode?: number | null,
}

export type TerminalRequestTableKey = {
  label: string,
  key: string,
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify' | null,
}

export const tableKeys = [
  { label: "Ügyfél neve", key: "partnerName"},
  { label: "Telephely neve", key: "seat"},
  { label: "Telephely címe", key: "fullAddress"},
  { label: "Terminál típusa", key: "terminalTypeName"},
  { label: "Igénylés ideje", key: "createdAtDate" },
];
