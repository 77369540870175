import { CustomStyle } from "../../GlobalTypes";

export const style: CustomStyle = {
  gridLabel: {
    fontWeight: 600,
    fontSize: 18,
  },
  title: {
    marginBottom: 32,
  },
  subTitle: {
    marginTop: 64,
    marginBottom: 32,
  },
  paper: {
    margin: 32,
    padding: 16,
    maxWidth: 'none',
  },
  buttonContainer:{
    maxWidth: 'none',
    justifyContent: 'flex-end',
    display: 'flex',
    gap: 16,
    marginBottom: 16,
    marginTop: 64,
    padding: 0,
  },
  switchGrid:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};