// -----------------------------------------------------------------Imports---
import { Input, InputAdornment, InputBaseComponentProps } from '@mui/material';
import { GridSize, InputProps } from '@mui/material';
import { forwardRef } from 'react';
import { ReactElement } from 'react-imask/dist/mixin';
import { DeliveryAddressModel } from './DeliveryAddressModel';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = forwardRef<ReactElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00000000-0-00"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

// -----------------------------------------------------------------Privates---
export type PartnerModel = {
  bankAccountNumber?: string | null,
  building?: string | null,
  city?: string | null,
  comment?: string | null,
  customerCode?: string,
  door?: string | null,
  deliveryAddresses: DeliveryAddressModel[],
  email?: string | null,
  floor?: string | null,
  houseNumber?: string | null,
  name?: string | null,
  parcelNumber?: string | null,
  partnerCode: number,
  postalCode?: string | null,
  staircase?: string | null,
  streetName?: string | null,
  streetType?: string | null,
  taxNumber?: string | null,
  telephone?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  updatedByUser?: object | null,
  representative?: string | null,
  notificationAddress?: string | null,
  //casted data
  fullAddress?: string | null,
}

export type PartnerTableKey = {
  label: string,
  key: string,
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify' | null,
}

export type PartnerGridKey = {
  label: string,
  key: string,
  inputHidden?: true | null,
  md?: GridSize | null,
  sm?: GridSize | null,
  xs?: GridSize | null,
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify' | null,
  mandatory?: true | null,
  //for now, its for input-adornment
  InputProps?: InputProps,
  inputProps?: InputBaseComponentProps,
  customInput?: (props: any) => JSX.Element, 
}

export const tableKeys: PartnerTableKey[] = [
  { label: 'Cégnév', key: 'name', },
  { label: 'Adószám', key: 'taxNumber', },
  { label: 'Székhely', key: 'fullAddress', },
  // { label: 'Bankszámlaszám', key: 'bankAccountNumber', },
  { label: 'Képviselő', key: 'representative', },
  { label: 'Telefon', key: 'telephone', },
  { label: 'Email', key: 'email', },
];

export const gridKeys: PartnerGridKey[] = [
  { label: 'Kód', key: 'partnerCode', inputHidden: true, md: 4, mandatory: true },
  { label: 'Partner név', key: 'name', md: 4, mandatory: true,
    inputProps: { maxlength: 40, },
  },
  { label: 'Adószám', key: 'taxNumber', md: 4, mandatory: true,
    //its for the imask xxxxxxxx-x-xx
    customInput: (props: any): JSX.Element => <Input
      fullWidth
      value={props.value}
      onChange={(e) => {props.onChange(e);}}
      inputComponent={TextMaskCustom as any}
    />
  },
  // { label: 'Bankszámlaszám', key: 'bankAccountNumber', md: 4, mandatory: true },
  { label: 'Képviselő', key: 'representative', md: 4, mandatory: true },
  { 
    label: 'Telefon', key: 'telephone', md: 4, mandatory: true, 
    inputProps: { startAdornment: <InputAdornment style={{ paddingBottom:2, marginRight: 5 }} position="start">+ 36 </InputAdornment> } 
  },
  { label: 'Email', key: 'email', md: 4, mandatory: true },
];

export const gridKeys2: PartnerGridKey[] = [
  { label: 'Irányítószám', key: 'postalCode', mandatory: true },
  { label: 'Város', key: 'city', mandatory: true },
  { label: 'Közterület neve', key: 'streetName', mandatory: true },
  { label: 'Közterület jellege', key: 'streetType', mandatory: true },
  { label: 'Házszám', key: 'houseNumber', mandatory: true },
  { label: 'Épület', key: 'building', },
  { label: 'Lépcsőház', key: 'staircase', },
  { label: 'Emelet', key: 'floor', },
  { label: 'Ajtó', key: 'door', },
  { label: 'Helyrajzi szám', key: 'parcelNumber', },
  { label: 'Értesítési cím', key: 'notificationAddress', md: 6, sm: 12, },
  // { label: 'Megjegyzés', key: 'comment', },
];

export type ToDeleteDelivery = {
  id: number,
  name: string
}
