// -----------------------------------------------------------------Imports---
import { CustomStyle } from '../../GlobalTypes';

// -----------------------------------------------------------------Exports---
export const HomePageStyles: CustomStyle = {
  box:{
    padding: 32,
  },
  button:{
    width: '100%',
    backgroundColor: 'rgb(253, 216, 53)',
    color: 'black',
    padding: 24,
    marginTop: 8,
    marginBottom: 8,
  }
}
