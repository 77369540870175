// -----------------------------------------------------------------Imports---
import { ReportHandler } from 'web-vitals';

// -----------------------------------------------------------------Exports---
export default function reportWebVitals(onPerfEntry?: ReportHandler): void {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals')
      .then(function ({ getCLS, getFID, getFCP, getLCP, getTTFB }): void {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      });
  }
};
