import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { Key } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { drawerAppRoutes } from "../../Global";
import { AppRoute } from "../../GlobalTypes";
import { HomePageStyles } from "./HomePage.style";

// -----------------------------------------------------------------Exports---
export default function HomePage(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  const onButton = (route: string): void => {
    navigate(route);
  }
  // --------------------------------------------------------------Return---
  return (
    <Box style={HomePageStyles.box}>
      {drawerAppRoutes.filter((route: AppRoute) => route.homeShow).map((route: AppRoute, i: Key): JSX.Element => {
        return(
          <Button 
            style={HomePageStyles.button}
            variant="contained"
            onClick={() => onButton(route.route)}
            key={i}
          >
            {route.text}
          </Button>
        )
      })}
    </Box>
  );
}
