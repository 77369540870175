import { CustomStyle } from "../../GlobalTypes";

export const style: CustomStyle = {
  paper: {
    margin: 32,
    padding: 16
  },
  label:{
    marginLeft: 4,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 600,
  },
  formControl:{
    width: '100%',
  },
  button:{
    height: 56,
  },
  buttonBox:{
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
    gap: 16,
  },
};