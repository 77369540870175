// -----------------------------------------------------------------Imports---
import axios, { AxiosError } from 'axios';

import {
  getAppApiRoutes,
  getAppApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import LoginService from "../login/LoginService";

// ----------------------------------------------------------------Privates---
class TerminalRequestService {
  async addTerminalRequest(data: any, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .post(
        getAppApiUrlBase() + getAppApiRoutes.terminalRequest.addTerminalRequest,
        data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token')
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.addTerminalRequest(data, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }

  async getTerminalRequests(data: any = {}, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .post(
        getAppApiUrlBase() + getAppApiRoutes.terminalRequest.getTerminalRequests,
        {
          orderBy: "createdAt_desc",
          filter: data
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token')
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.getTerminalRequests(data, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }

  async updateTerminalRequest(id: number, data: any = {}, logout: () => void): Promise<ResponseModel> {
    const self = this;
    let responseData: ResponseModel = {
      status: 400,
      data: null,
      error: {
        message: 'Ismeretlen hiba történt...',
      },
    }

    await axios
      .put(
        getAppApiUrlBase() + getAppApiRoutes.terminalRequest.updateTerminalRequests.replace('{id}', (id as unknown) as string),
        data,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('micra_token')
          }
        }
      )
      .then(function (response: any): void {
        responseData = response.data;
      })
      .catch(async function (error: AxiosError): Promise<void> {
        if (error.response!.status === 401) {
          let response2: ResponseModel = { status: 401 };
          let i: number = 0;
          while(response2.status === 401 && i < 3){
            response2 = await LoginService.onRefresh();
            i++;
          }

          if(response2.status === 401){
            logout();
            responseData.error!.message = 'Lejárt a bejelentkezése';
            return;
          }
          else{
            responseData = await self.updateTerminalRequest(id, data, logout);
          }
        }
        else {
          responseData = (error.response! as any).data!;
        }
      });

    return responseData;
  }
}

// -----------------------------------------------------------------Exports---
const service = new TerminalRequestService();
export default service;