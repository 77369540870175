import { BooleanDictionary } from "../GlobalTypes";
import { DeliveryAddressModel } from "../models/DeliveryAddressModel";
import { PartnerModel } from "../models/PartnerModel";

export const getLongAddress = (model: DeliveryAddressModel | PartnerModel, showName: boolean = false): string => {
  if(model === undefined || model === null) return '';
  
  return (model.name && showName ? model.name + ', ' : '')
  + (model.postalCode ? model.postalCode + ' ' : '')
  + (model.city ? model.city + ' ' : '')
  + (model.streetName ? model.streetName + '' : '')
  + (model.streetType ? '\xa0' + model.streetType + '\xa0' : '')
  + (model.houseNumber ? model.houseNumber + '\x20\x20\x20' : '')
  + (model.building ? model.building + '\xa0ép. ' : '')
  + (model.staircase ? model.staircase + '\xa0lh. ' : '')
  + (model.floor ? model.floor + '\xa0sz. ' : '')
  + (model.door ? model.door + '\xa0a.' : '');
};

export const doneChecker = (done: BooleanDictionary): boolean => {
  let keys: string[] = Object.keys(done);
  for(let key of keys){
    if(!done[key]){ return false; }
  }
  return true;
}
