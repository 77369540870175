// -----------------------------------------------------------------Imports---
import {
  Key,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  IconButton,
  Button,
  Container,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { style } from './TerminalRequests.style';

import { tableKeys, TerminalRequestModel, TerminalRequestTableKey } from '../../models/TerminalRequestModel';
import { toast } from 'react-toastify';
import TerminalRequestService from '../../services/terminalRequests/TerminalRequestService';
import { useEffect } from 'react';
import Transition from '../../components/Transition/Transition';
import CreateTerminalRequest from './createTerminalRequest';
import AddTerminalOwner from './addTerminalOwner';
import PrintIcon from '@mui/icons-material/Print';
import { getFileApiUrlBase } from '../../Global';
import { getLongAddress } from '../../utils/Utils';
import ResponseModel from '../../models/ResponseModel';
import { GlobalContext, GlobalContextType } from '../../App';

// -----------------------------------------------------------------Exports---
export default function TerminalRequests(): JSX.Element {
  const [terminalRequests, setTerminalRequests] = useState<TerminalRequestModel[]>([]);
  const [filteredTerminalRequests, setFilteredTerminalRequests] = useState<TerminalRequestModel[]>([]);
  const [selectedTerminalRequest, setSelectedTerminalRequest] = useState<TerminalRequestModel | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [nameFilter, setNameFilter] = useState<string>('');
  const [isSetterOpen, setSetterOpen] = useState<boolean>(false);
  const [isOwnerSetterOpen, setOwnerSetterOpen] = useState<boolean>(false);
  const globalContext = useContext<GlobalContextType | null>(GlobalContext);

  //get all the requests
  const getTerminalRequests = useCallback(async (): Promise<void> => {
    let response: ResponseModel = await TerminalRequestService.getTerminalRequests({}, globalContext!.logOut);

    //showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
      return;
    }
    else{
      let data: TerminalRequestModel[] = response.data as TerminalRequestModel[];
      //cast some data for easier displaying
      data = data.filter((data: TerminalRequestModel) => data.status !== 'K' && data.status !== 'X');
      data.forEach((request: TerminalRequestModel) => {  
        request.partnerName = request.deliveryAddress?.partner?.name;
        request.seat = request.deliveryAddress.name;
        request.terminalTypeName = request.terminalType.name;
        request.createdAtDate = (new Date(request.createdAt!)).toLocaleString()
                                  .substring(0, (new Date(request.createdAt!)).toLocaleString().length - 3);
        request.fullAddress = getLongAddress(request.deliveryAddress);
      });  
      setTerminalRequests(data);
      setFilteredTerminalRequests(data);
    }
  }, [globalContext]);

  //download the contract and view it in a new tab
  const getContract = (terminalRequest: TerminalRequestModel): void => {
    window.open(getFileApiUrlBase() + terminalRequest.terminalContract.fileUrl); 
  }

  //render the head once
  const headMemo = useMemo(() => {
    return tableKeys.map((tableKey: TerminalRequestTableKey, i: Key) => {
      return (
        <TableCell key={i} style={style.tableHeadCell} align={tableKey.align ?? 'left'}>{tableKey.label}</TableCell>
      )
    });
  }, []);

  //paging
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  //changing row amount
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  //filtering the data
  const nameFilterOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNameFilter(event.target.value);
    if (event.target.value.length > 2) {
      let newRequests: TerminalRequestModel[] = [];
      newRequests = terminalRequests.filter((request: TerminalRequestModel) => {
        return request.partnerName?.toLowerCase().includes(event.target.value.toLowerCase())
      });
      setFilteredTerminalRequests(newRequests);
    }
    else if (filteredTerminalRequests !== terminalRequests) {
      setFilteredTerminalRequests(terminalRequests);
    }
  };

  //get the data on load
  useEffect(() => {
    getTerminalRequests();
  }, [getTerminalRequests]);

  // --------------------------------------------------------------Return---
  return (
    <Paper style={style.paper}>

      {/* Header */}
      <Typography variant="h3" style={style.title}>Igénylések listázása</Typography>
      <Container style={style.container}>
        <TextField
          variant="outlined"
          value={nameFilter}
          onChange={nameFilterOnChange}
          style={style.filterTextField}
          label="Ügyfél név"
        />
        <Button onClick={() => setSetterOpen(true)} variant="contained" style={style.navButton}>
          Új igénylés
        </Button>
      </Container>

      {/* Table */}
      <TableContainer style={style.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {headMemo}
              <TableCell style={style.tableHeadCell}/>
              <TableCell style={style.tableHeadCell}/>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTerminalRequests
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((request: TerminalRequestModel, i: Key): JSX.Element => {
                return (
                  <TableRow
                    key={i}
                    style={style.dataRow}
                  >
                    {tableKeys.map((tableKey: TerminalRequestTableKey, j: Key): JSX.Element => {
                      return (
                        <TableCell
                          align={tableKey.align ?? 'left'}
                          style={style.dataCell}
                          key={j}
                        >
                          {(request as any)[tableKey.key]}
                        </TableCell>
                      )
                    })}
                    <TableCell
                      align={'center'}
                      style={style.dataCell}
                    >
                      {
                        request.terminalContract &&
                        request.terminalContract.fileUrl &&
                        <IconButton 
                          size="large" 
                          color="success"                         
                          onClick={() => {getContract(request);}}
                        >
                          <PrintIcon fontSize="inherit" />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell
                      align={'center'}
                      style={style.dataCell}
                    >
                      {request.tid !== null && request.status === 'E' &&
                        <Button
                          onClick={() => {setSelectedTerminalRequest(request);setOwnerSetterOpen(true);}}
                        >
                          Kihelyezés
                        </Button>
                      }                      
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        component="div"
        count={filteredTerminalRequests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Add request modal */}
      <Dialog
        TransitionComponent={Transition}
        maxWidth={false}
        PaperProps={{style:{width: '100%'}}}
        open={isSetterOpen}
        onClose={() => setSetterOpen(false)}
      >
        <CreateTerminalRequest setDialogOpen={setSetterOpen} getTerminalRequests={getTerminalRequests}/>
      </Dialog>

      {/* Add owner modal */}
      <Dialog
        TransitionComponent={Transition}
        maxWidth={false}
        PaperProps={{style:{width: '100%'}}}
        open={isOwnerSetterOpen}
        onClose={() => setOwnerSetterOpen(false)}
      >
        <AddTerminalOwner getTerminalRequests={getTerminalRequests} request={selectedTerminalRequest} setOwnerSetterOpen={setOwnerSetterOpen}/>
      </Dialog>
    </Paper>
  );
}
