import { CustomStyle } from "../../GlobalTypes";

// -----------------------------------------------------------------Exports---
export const style: CustomStyle = {
  dialogPaper: {
    width: '100%',
  },
  box: {
    padding: 24,
    width: '100%',
  },
  typography: {
    textAlign: 'center',
    fontSize: 26,
    marginBottom: 12,
  },
}