import { CustomStyle } from "../../GlobalTypes";

// -----------------------------------------------------------------Exports---
export const style: CustomStyle = {
  title: {
    marginBottom: 32
  },
  container: {
    maxWidth: 'none',
    display: 'flex',
    justifyContent: 'space-between',
  },
  navButton: {
    height: 56,
    margin: 16,
  },
  tableHeadCell: {
    borderBottom: 'none',
  },
  paper: {
    margin: 32,
    padding: 16,
  },
  filterTextField: {
    margin: 16,
    marginBottom: 0,
  },
  tableContainer: {
    padding: 16,
  },
  dataRow: {
    backgroundColor: '#353535',
    borderBottom: 'gray 1px solid',
    borderTop: 'gray 1px solid',
  },
  dataCell: {
    borderBottom: 'none',
    whiteSpace: 'break-spaces',
  },
  accordionCell: {
    borderBottom: 'none',
    padding: 0,
  },
  gridLabel: {
    fontWeight: 600,
    fontSize: 18,
  },
  buttonContainer:{
    maxWidth: 'none',
    justifyContent: 'flex-end',
    display: 'flex',
    gap: 16,
    marginBottom: 16,
    marginTop: 16,
    padding: 0,
  },
  saveButton: {
    margin: 16,
  },
}
