// -----------------------------------------------------------------Imports---
import {
  NavigateFunction,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import {
  createContext,
  useEffect,
  useState
} from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import {
  Box,
  CssBaseline,
  ThemeProvider,
} from '@mui/material';

import { GlobalLightTheme, GlobalDarkTheme, styles } from './App.style';
import { getAppRoutes, isDark as GlobalIsDark } from './Global';

import HomePage from './pages/home/HomePage';
import LoginPage from './pages/login/LoginPage';
import NotFoundPage from './pages/notFound/NotFoundPage';
import Partners from './pages/partners/Partners';
import AccordionTableExample from './components/examples/AccordionTable/AccordionTableExample';
import Drawer from './components/drawer/Drawer';
import TerminalRequests from './pages/terminalRequests/TerminalRequests';
import Terminals from './pages/terminals/Terminals';
import jwt_decode from 'jwt-decode';

// -----------------------------------------------------------------Context---
export interface GlobalContextType {
  logOut: () => void,
}

export const GlobalContext = createContext<GlobalContextType | null>(null);

// -----------------------------------------------------------------Exports---
export default function App(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  const [loggedInState, setLoggedInState] = useState<boolean>(localStorage.getItem('micra_token') ? true : false);
  const [loggedInUser, setLoggedInUser] = useState<string>(
    localStorage.getItem('micra_token') 
      ? (jwt_decode(localStorage.getItem('micra_token')!.split(' ')[1]) as any)?.sub
      : ''
  );
  const [isBoxSmall, setBoxSmall] = useState<boolean>(false);
  const [isDark, setDark] = useState<boolean>(GlobalIsDark);

  useEffect(function (): void {
    const currentPath: string = window.location.href.substring(window.location.href.lastIndexOf('/'));
    const exceptPaths: string[] = [getAppRoutes.login, getAppRoutes.registration];

    if ((loggedInState) && (exceptPaths.indexOf(currentPath) !== -1)) {
      navigate(getAppRoutes.home);
    }
    else if ((!loggedInState) && (exceptPaths.indexOf(currentPath) === -1)) {
      navigate(getAppRoutes.login);
    }
    // eslint-disable-next-line
  }, [loggedInState]);

  window.onerror = function (event: string | Event, source?: string | undefined, lineno?: number | undefined, colno?: number | undefined, error?: Error | undefined): void {
    console.log(event);
    // TODO: Loggerbe írás
  }

  const logOut = (): void => {
    localStorage.removeItem('micra_token');
    setLoggedInState(false);
    setLoggedInUser('');
  };

  // --------------------------------------------------------------Return---
  return (
    <ThemeProvider theme={isDark ? GlobalDarkTheme : GlobalLightTheme}>
      <CssBaseline enableColorScheme />
      <ToastContainer theme={'dark'} position={'bottom-right'} />
      <GlobalContext.Provider
        value={{ logOut: logOut }}
      >
        <Drawer logOut={logOut} loggedInState={loggedInState} loggedInUser={loggedInUser} setBoxSmall={setBoxSmall} setDark={setDark}/>
        <Box 
          style={
            !loggedInState
              ? styles.loggedOutBox
              : isBoxSmall
                ? styles.loggedInSmallBox
                : styles.loggedInBigBox         
          }
        >
          <Routes>
            <Route element={<LoginPage setLoggedInState={setLoggedInState} setLoggedInUser={setLoggedInUser} loggedInState={loggedInState} />} path={getAppRoutes.login} />
            <Route element={<HomePage />} path={getAppRoutes.home} />
            <Route element={<Partners />} path={getAppRoutes.partners} />
            <Route element={<AccordionTableExample />} path={getAppRoutes.accordionTableExample} />
            <Route element={<TerminalRequests />} path={getAppRoutes.terminalRequests} />
            <Route element={<Terminals/>} path={getAppRoutes.terminals} />
            <Route element={<NotFoundPage />} path={getAppRoutes.notFound} />
          </Routes>
        </Box>
      </GlobalContext.Provider>
    </ThemeProvider>
  );
}
