import { GridSize } from "@mui/material";
import { PartnerModel } from "./PartnerModel";

export type DeliveryAddressModel = {
  building?: string | null,
  city?: string | null,
  deliveryAddressCode: number,
  door?: string | null,
  floor?: string | null,
  houseNumber?: string | null,
  name?: string | null,
  parcelNumber?: string | null,
  partner?: PartnerModel | null,
  partnerCode: BigInteger,
  postalCode?: string | null,
  staircase?: string | null,
  streetName?: string | null,
  streetType?: string | null,
  telephone?: string | null,
  updatedAt?: string | null,
  updatedBy?: string | null,
  updatedByUser?: object | null,
  //casted data
  fullAddress?: string | null,
}

export type DeliveryAddressGridKey = {
  label: string,
  key: string,
  inputHidden?: true | null,
  displayDisabled?: true | null,
  md?: GridSize | null,
  sm?: GridSize | null,
  xs?: GridSize | null,
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify' | null,
}

export const gridKeys: DeliveryAddressGridKey[] = [
  { label: 'Üzlet/Telephely neve', key: 'name', },
  { label: 'Cím', key: 'fullAddress', inputHidden: true },
  { label: 'Irányítószám', key: 'postalCode', displayDisabled: true },
  { label: 'Város', key: 'city', displayDisabled: true },
  { label: 'Közterület neve', key: 'streetName', displayDisabled: true },
  { label: 'Közterület jellege', key: 'streetType', displayDisabled: true },
  { label: 'Házszám', key: 'houseNumber', displayDisabled: true },
  { label: 'Épület', key: 'building', displayDisabled: true },
  { label: 'Lépcsőház', key: 'staircase', displayDisabled: true },
  { label: 'Emelet', key: 'floor', displayDisabled: true },
  { label: 'Ajtó', key: 'door', displayDisabled: true },
  { label: 'Helyrajzi szám', key: 'parcelNumber', displayDisabled: true },
]