// -----------------------------------------------------------------Imports---
import { Box, Dialog, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { style } from "./LoadingDialog.style";

// -----------------------------------------------------------------Exports---
export default function LoadingDialog(){
  return(
    <Dialog open={true} PaperProps={{style:style.dialogPaper}}>
      <Box style={style.box}>
        <Typography style={style.typography}>Adatok lekérése</Typography>
        <LinearProgress/>
      </Box>
    </Dialog>
  )
}