// -----------------------------------------------------------------Imports---
import React, {
  Key,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Dialog,
  IconButton
} from '@mui/material';

import {
  PartnerModel,
  PartnerTableKey,
  tableKeys,
  ToDeleteDelivery,
} from '../../models/PartnerModel';

import {
  DeliveryAddressGridKey,
  DeliveryAddressModel,
  gridKeys as DeliveryGridKeys
} from '../../models/DeliveryAddressModel';

import ResponseModel from '../../models/ResponseModel';

import { style } from './Partners.style';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../components/TableAccordions/TableAccordions';

import PartnerService from '../../services/partners/PartnerService';
import DeliveryAddressService from '../../services/deliveryAddresses/deliveryAddressService';
import { toast } from 'react-toastify';
import AddPartner from './AddPartner';
import AddDeliveryAddress from '../deliveryAddresses/AddDeliveryAddress';
import Transition from '../../components/Transition/Transition';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Divider } from '@mui/material';
import { doneChecker, getLongAddress } from '../../utils/Utils';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import { BooleanDictionary } from '../../GlobalTypes';
import { useDebounce } from 'usehooks-ts';
import { GlobalContext, GlobalContextType } from '../../App';

// -----------------------------------------------------------------Exports---
export default function Partners(): JSX.Element {
  const [partners, setPartners] = useState<PartnerModel[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [maxData, setMaxData] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [nameFilter, setNameFilter] = useState<string>('');
  const debouncedName = useDebounce<string>(nameFilter, 1000);
  const [VATFilter, setVATFilter] = useState<string>('');
  const debouncedVAT = useDebounce<string>(VATFilter, 1000);
  const [expanded, setExpanded] = useState<number | false>(false);
  const [isShowingDeleteDeliveryModal, setShowingDeleteDeliveryModal] = useState<boolean>(false);
  const [toDeleteDelivery, setToDeleteDelivery] = useState<ToDeleteDelivery>({} as ToDeleteDelivery);
  const [isSetterOpen, setSetterOpen] = useState<boolean>(false);
  const [updateID, setUpdateID] = useState<number | undefined>(undefined);
  const [isDeliverySetterOpen, setDeliverySetterOpen] = useState<boolean>(false);
  const [updateDeliveryID, setUpdateDeliveryID] = useState<number | string | undefined>(undefined);
  const [partnerForDelivery, setPartnerForDelivery] = useState<PartnerModel | null>(null);
  const [done, setDone] = useState<PartnersDone>({partners: false});
  const globalContext = useContext<GlobalContextType | null>(GlobalContext);

  //render the head once
  const headMemo = useMemo(() => {
    return tableKeys.map((tableKey: PartnerTableKey, i: Key) => {
      return (
        <TableCell key={i} style={style.tableHeadCell} align={tableKey.align ?? 'left'}>{tableKey.label}</TableCell>
      )
    });
  }, []);

  //paging
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  //changing row amount
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  //accordion handling
  const accordionChange = (panel: number) => (): void => {
    if (expanded === panel)
      setExpanded(false);
    else
      setExpanded(panel);
  };

  //get the data
  const getPartners = useCallback(async (): Promise<void> => {
    let data: any = {filter:{}};
    // apply filters
    if(debouncedName.length > 2) data.filter.name = debouncedName.toLowerCase();
    if(debouncedVAT.length > 2) data.filter.taxNumber = debouncedVAT.toLowerCase();
    if(debouncedName.length <= 2 && debouncedName.length <= 2)
      data.pagination = {currentPage: page + 1, dataPerPage: rowsPerPage};

    let partners: ResponseModel = await PartnerService.getPartners(data, globalContext!.logOut);
    //showing the result
    if (partners.error !== null) {
      toast.error(partners.error?.message);
      setDone(done => {done.partners = true; return done});
      return;
    }

    let partnersData: PartnerModel[] = partners.data as PartnerModel[];

    partnersData.forEach((partner) => {
      partner.fullAddress = getLongAddress(partner);
      partner.deliveryAddresses.forEach((address) => {
        address.fullAddress = getLongAddress(address);
      });        
    });
    if((page) * rowsPerPage > partners.pagination!.totalDataCount)
      setPage(0);
    setMaxData(partners.pagination!.totalDataCount)
    setPartners(partnersData);

    if(!done.partners)
      setDone(done => {done.partners = true; return done});
  }, [debouncedName, debouncedVAT, done.partners, page, rowsPerPage, globalContext]);

  //filtering the data
  const filterOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
    key: string, 
    setter: React.Dispatch<React.SetStateAction<string>>
  ): void => {
    //update the state  with the new value
    setter(event.target.value);
  };

  //show a second window before deleting a deliveryAddress, prep the data
  const onDeliveryDeleteButton = (_toDeleteDelivery: ToDeleteDelivery): void => {
    setToDeleteDelivery(_toDeleteDelivery);
    setShowingDeleteDeliveryModal(true);
  };

  //delete the selected deliveryAddress
  const deleteDelivery = useCallback(async (): Promise<void> => {
    let response: ResponseModel = await DeliveryAddressService.deleteDeliveryAddress(toDeleteDelivery.id, globalContext!.logOut);

    if (response.error !== null) {
      toast.error(response.error?.message);
    }
    else {
      toast.success('Sikeres szállítási cím törlés');
      getPartners();
      setShowingDeleteDeliveryModal(false);
    }
  }, [getPartners, globalContext, toDeleteDelivery.id]);

  //show modal for creating new partner
  const onNewPartner = () => {
    setUpdateID(undefined);
    setSetterOpen(true);
  }

  //show modal for creating new deliveryAddress
  const onNewDeliveryAddress = (partner: PartnerModel) => {
    setUpdateDeliveryID(undefined);
    setPartnerForDelivery(partner);
    setDeliverySetterOpen(true);
  }

  //show modal for updating a partner
  const onUpdate = (partnerCode: number) => {
    setUpdateID(partnerCode);
    setSetterOpen(true);
  }

  //show modal for updating a deliveryAddress
  const onUpdateDelivery = (deliveryAddressCode: number) => {
    setUpdateDeliveryID(deliveryAddressCode.toString());
    setDeliverySetterOpen(true);
  }

  // get data on load
  useEffect(() => {
    getPartners();
  // eslint-disable-next-line
  }, [rowsPerPage, page, debouncedName, debouncedVAT]);

  // blocking with a dialog while getting all the data
  if(!doneChecker(done)){
    return <LoadingDialog/>
  }

  // --------------------------------------------------------------Return---
  return (
    <Paper style={style.paper}>
      {/* Header */}
      <Typography variant="h3" style={style.title}>Ügyfelek listája</Typography>
      <Container style={style.container}>
        <Box>
          <TextField
            variant="outlined"
            value={nameFilter}
            onChange={(e) => filterOnChange(e, 'name', setNameFilter)}
            style={style.filterTextField}
            label="Cégnév"
          />
          <TextField
            variant="outlined"
            value={VATFilter}
            onChange={(e) => filterOnChange(e, 'taxNumber', setVATFilter)}
            style={style.filterTextField}
            label="Adószám"
          />
        </Box>
        <Button onClick={onNewPartner} variant="contained" style={style.navButton}>
          Új ügyfél
        </Button>
      </Container>

      {/* Table */}
      <TableContainer style={style.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {headMemo}
            </TableRow>
          </TableHead>
          <TableBody>
            {partners
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((partner: PartnerModel, i: Key): JSX.Element => {
                return (
                  <React.Fragment key={i}>
                    {/* Partner data */}
                    <TableRow
                      onClick={accordionChange(partner.partnerCode)}
                      style={style.dataRow}
                    >
                      {tableKeys.map((tableKey, j): JSX.Element => {
                        return (
                          <TableCell
                            align={tableKey.align ?? 'left'}
                            style={style.dataCell}
                            key={j}
                          >
                            {(partner as any)[tableKey.key]}
                          </TableCell>
                        )
                      })}
                    </TableRow>

                    {/* DeliveryAddresses */}
                    <TableRow>
                      <TableCell style={style.accordionCell} colSpan={tableKeys.length}>
                        <Accordion expanded={expanded === partner.partnerCode}>
                          <AccordionSummary />
                          <AccordionDetails style={style.accordionDetails}>
                            {partner.deliveryAddresses.map((address: DeliveryAddressModel, j: Key): JSX.Element => {
                              return(
                                <React.Fragment key={j}>
                                  <Grid container spacing={4} style={style.gridContainer}>
                                    <Grid item container xs={2} direction="row">
                                      <Grid item xs={6}>
                                        <IconButton size="large" color="primary" onClick={() => onUpdateDelivery(address.deliveryAddressCode)}>
                                          <EditIcon fontSize="inherit" />
                                        </IconButton>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <IconButton size="large" color="error" onClick={() => onDeliveryDeleteButton({id: address.deliveryAddressCode,name: address.name + ' ' + address.fullAddress} as ToDeleteDelivery)}>
                                          <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                    {DeliveryGridKeys
                                    .filter((gridKey: DeliveryAddressGridKey) => gridKey.displayDisabled !== true)
                                    .map((gridKey: DeliveryAddressGridKey, k: Key): JSX.Element => {
                                      return(
                                        <Grid key={k} item md={gridKey.md ?? 3} sm={gridKey.sm ?? 5} xs={gridKey.xs ?? 5}>
                                          <Typography key={1} style={style.gridLabel} align={gridKey.align ?? 'left'}>{gridKey.label}</Typography>
                                          <Typography key={2} align={gridKey.align ?? 'left'}>{(address as any)[gridKey.key]}</Typography>
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                  <Divider />
                                </React.Fragment>
                              )
                            })}
                            <Container style={style.buttonContainer}>
                              <Button variant="contained" size="large" color="success" onClick={() => onNewDeliveryAddress(partner)}>
                                Szállítási cím hozzáadása
                              </Button>
                              <Button variant="contained" onClick={() => onUpdate(partner.partnerCode)}>Ügyfél szerkesztése</Button>
                            </Container>
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        component="div"
        count={maxData}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Delete modal */}
      <Modal
        open={isShowingDeleteDeliveryModal}
        onClose={() => setShowingDeleteDeliveryModal(false)}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: '24',
          p: 4,
        }}>
          <Typography variant="h6">
            Biztosan törölni akarod a(z) {toDeleteDelivery.name}-t?
          </Typography>
          <Container style={style.buttonContainer}>
            <Button variant="contained" onClick={() => setShowingDeleteDeliveryModal(false)}>Mégse</Button>
            <Button variant="contained" color="error" onClick={deleteDelivery}>Törlés</Button>                   
          </Container>
        </Box>
      </Modal>

      {/* Add modal */}
      <Dialog
        TransitionComponent={Transition}
        maxWidth={false}
        open={isSetterOpen}
        onClose={() => setSetterOpen(false)}
      >
        <AddPartner id={updateID} setSetterOpen={setSetterOpen} getPartners={getPartners}/>
      </Dialog>

      {/* Add address modal */}
      <Dialog
        TransitionComponent={Transition}
        maxWidth={false}
        open={isDeliverySetterOpen}
        onClose={() => setDeliverySetterOpen(false)}
      >
        <AddDeliveryAddress partnerForDelivery={partnerForDelivery} id={updateDeliveryID} setDeliverySetterOpen={setDeliverySetterOpen} getPartners={getPartners}/>
      </Dialog>
    </Paper>
  );
}

type PartnersDone = BooleanDictionary & {
  partners: boolean,
}