// -----------------------------------------------------------------Imports---
import { GridSize } from '@mui/material';

// -----------------------------------------------------------------Privates---
export type ExampleModel = {
  id: number,
  firstName: string,
  lastName?: string | null,
  age?: number | null,
  updaterName?: string | null,
}

export type ExampleTableKey = {
  label: string,
  key: string,
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify' | null,
}

export type ExampleGridKey = {
  label: string,
  key: string,
  inputHidden?: true | null,
  md?: GridSize | null,
  sm?: GridSize | null,
  xs?: GridSize | null,
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify' | null,
}

export const tableKeys: ExampleTableKey[] = [
  { label: 'Azonosító', key: 'id', },
  { label: 'K. Név', key: 'firstName', },
  { label: 'V. Név', key: 'lastName', },
  { label: 'Kor', key: 'age', },
  { label: 'Szerkesztő', key: 'updaterName', },
];

export const gridKeys: ExampleGridKey[] = [
  { label: 'Azonosító', key: 'id', inputHidden: true },
  { label: 'K. Név', key: 'firstName', },
  { label: 'V. Név', key: 'lastName', },
  { label: 'Kor', key: 'age', },
  { label: 'Szerkesztő', key: 'updaterName', },
];

export const exampleArray: ExampleModel[] = [
  {
    id: 0,
    firstName: 'Elek',
    lastName: 'Teszt',
    age: 33,
    updaterName: null,
  },
  {
    id: 1,
    firstName: 'Fülemüle',
    lastName: null,
    age: null,
    updaterName: 'TesztElek'
  }
]
