import { Key, useState } from 'react';
import { IconButton, Toolbar, Box, Switch } from '@mui/material';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@mui/icons-material/Description';
import { AppBar, DrawerBase, DrawerHeader, DrawerStyle } from './Drawer.style';
import { drawerAppRoutes, isDark as GlobalDark } from '../../Global';
import { useNavigate } from 'react-router';
import MicraLogo from '../../images/micra.png';
import HBLogo from '../../images/forgalmazoi.png';
import { AppRoute } from '../../GlobalTypes';

export default function Drawer(props: DrawerProps): JSX.Element {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false); 
  const [title, setTitle] = useState<string>('');
  const [isDark, setDark] = useState<boolean>(GlobalDark);

  // open the drawer add more padding
  const handleDrawerOpen = (): void => {
    setOpen(true);
    props.setBoxSmall(true);
  };
  
  // close the drawer remove the padding
  const handleDrawerClose = (): void => {
    setOpen(false);
    props.setBoxSmall(false);
  };

  // theme switch
  const onSwitch = (e: React.ChangeEvent<HTMLInputElement>): void => { 
    setDark(e.target.checked); 
    props.setDark(e.target.checked); 
  }

  // navigate on list item
  const onDrawerListItem = (appRoute: AppRoute): void => {
    navigate(appRoute.route);
    setTitle(appRoute.text);

    // if(appRoute.route === '/' && !open){
    //   handleDrawerOpen();
    // }
  }

  // hide drawer, when not logged in
  if(!props.loggedInState){
    return <></>
  }

  return(    
    <>
      {/* AppBar and its content */}
      <AppBar position="fixed" open={open}>
        <Toolbar style={DrawerStyle.toolbar}>
          <Box display="flex" style={DrawerStyle.toolbarBox}>
            <Box display="flex" alignItems={'center'}>
              <IconButton
                color="inherit"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <img src={MicraLogo} height={32} alt="User Logo"/>
              <Typography variant="h6" noWrap component="div" marginLeft={'16px'}>
                {title}
              </Typography>
            </Box>
            <Box style={DrawerStyle.switchBox}>
              <Switch
                checked={isDark}
                onChange={onSwitch}
              />
              <Typography variant="h6" noWrap component="div">
                {isDark ? 'Sötét mód' : 'Világos mód' }
              </Typography>
            </Box>
          </Box>
          <img src={HBLogo} height={32} alt="Dev Logo"/>
        </Toolbar>
      </AppBar>

      {/* Drawer and its content */}
      <DrawerBase variant="permanent" open={open}>
        {/* Drawer button */}
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon style={DrawerStyle.whiteColor}/>
          </IconButton>
        </DrawerHeader>
        <Divider style={open ? DrawerStyle.lightBorderColor : DrawerStyle.transparentBorderColor}/>

        {/* Logged in user */}
        <Typography
          align='center'
          style={
            open
              ? DrawerStyle.username
              : DrawerStyle.transparentColor
          }
        >
          {props.loggedInUser}
        </Typography>
        <Divider style={DrawerStyle.lightBorderColor}/>

        {/* Routes */}
        <List>
          {drawerAppRoutes.map((appRoute: AppRoute, index: Key) => (
            <ListItem key={index} disablePadding sx={DrawerStyle.blockDisplay}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={() => onDrawerListItem(appRoute)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'white'
                  }}
                  >
                  {appRoute.icon}
                </ListItemIcon>
                <ListItemText primary={appRoute.text} sx={{ opacity: open ? 1 : 0, color: 'white' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {/* Logout option */}
        <Divider style={DrawerStyle.lightBorderColor}/>
        <List>
          <ListItem disablePadding sx={DrawerStyle.blockDisplay}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {window.open('http://crm.micra.hu/pdfs/Micra_react_manual.pdf')}}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <DescriptionIcon style={DrawerStyle.whiteColor}/>
              </ListItemIcon>
              <ListItemText primary={"Kezelési leírás"} sx={{ opacity: open ? 1 : 0, color: 'white' }} />
            </ListItemButton>
          </ListItem>
        </List>

        <Divider style={DrawerStyle.lightBorderColor}/>
        <List>
          <ListItem disablePadding sx={DrawerStyle.blockDisplay}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={props.logOut}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <LogoutIcon style={DrawerStyle.whiteColor}/>
              </ListItemIcon>
              <ListItemText primary={"Kijelentkezés"} sx={{ opacity: open ? 1 : 0, color: 'white' }} />
            </ListItemButton>
          </ListItem>
        </List>
        
      </DrawerBase>
    </>
  )
}

type DrawerProps = {
  // padding setter for toolbar
  setBoxSmall: React.Dispatch<React.SetStateAction<boolean>>,
  // theme setter
  setDark: React.Dispatch<React.SetStateAction<boolean>>,
  // login state values
  logOut: () => void,
  loggedInState: boolean,
  loggedInUser: string,
}
