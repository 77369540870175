// -----------------------------------------------------------------Imports---
import React, {
  Key,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Grid,
} from '@mui/material';

import { style } from './Terminals.style';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../components/TableAccordions/TableAccordions';

import { toast } from 'react-toastify';
import { useEffect } from 'react';
import TerminalService from '../../services/terminals/TerminalService';
import { getStateName, TerminalModel, tableKeys, TerminalTableKey, gridKeys, TerminalGridKey, TerminalFilter, TerminalState } from '../../models/TerminalModel';
import moment from 'moment';
import ReturnTerminal from './returnTerminal';
import Transition from '../../components/Transition/Transition';
import { Dialog } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import TerminalTypeService from '../../services/terminalTypes/TerminalTypeService';
import CreateTerminalRequest from '../terminalRequests/createTerminalRequest';
import { getLongAddress } from '../../utils/Utils';
import { TerminalTypeModel } from '../../models/TerminalTypeModel';
import ResponseModel from '../../models/ResponseModel';
import { GlobalContext, GlobalContextType } from '../../App';

// -----------------------------------------------------------------Exports---
export default function Terminals(): JSX.Element {
  const [terminals, setTerminals] = useState<TerminalModel[]>([]);
  const [filteredTerminals, setFilteredTerminals] = useState<TerminalModel[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const [isReturningOpen, setReturningOpen] = useState<boolean>(false);
  const [returningTerminal, setReturningTerminal] = useState<TerminalModel | undefined>(undefined);
  const [swappingTerminal, setSwappingTerminal] = useState<TerminalModel | undefined>(undefined);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [terminalTypes, setTerminalTypes] = useState<TerminalTypeModel[]>([]);
  const [isRequestDialog, setRequestDialog] = useState<boolean>(false);
  const [filter, setFilter] = useState<TerminalFilter>({
    name: '',
    serial: '',
    state: 'K',
    type: 'all',
  });
  const globalContext = useContext<GlobalContextType | null>(GlobalContext);

  //render the head once
  const headMemo = useMemo(() => {
    return tableKeys.map((tableKey: TerminalTableKey, i: Key) => {
      return (
        <TableCell key={i} style={style.tableHeadCell} align={tableKey.align ?? 'left'}>{tableKey.label}</TableCell>
      )
    });
  }, []);

  // filter the terminals when the filter changes
  useEffect(() => {
    let _terminals = [...terminals];
    _terminals = _terminals.filter((terminal: TerminalModel): boolean => {
      return( 
        (filter.name.length < 3 || (terminal.partner !== undefined && terminal.partner!.toLowerCase().includes(filter.name.toLowerCase())))
        && (filter.serial.length < 3 || terminal.serialNumber!.toLowerCase().includes(filter.serial.toLowerCase()))
        && (filter.state === 'all' || terminal.state === filter.state)
        && (filter.type === 'all' || terminal.terminalTypeCode?.toString() === filter.type)
      );
    });

    setFilteredTerminals(_terminals);
  // eslint-disable-next-line
  }, [filter]);

  // getting all the request types
  const getTerminalTypes = async (): Promise<void> => {
    let response: ResponseModel = await TerminalTypeService.getTerminalTypes(globalContext!.logOut);
    //showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
      return;
    }
    else{
      setTerminalTypes(response.data as TerminalTypeModel[]);
    }
  }

  // get the terminals
  const getTerminals = async (): Promise<void> => {
    let response: ResponseModel = await TerminalService.getTerminals({}, globalContext!.logOut);
    let data: TerminalModel[];
    // showing the result
    if (response.error !== null) {
      toast.error(response.error?.message);
      return;
    }
    else{
      data = response.data as TerminalModel[];
      data.forEach((terminal: TerminalModel) => {
        terminal.partner = terminal.tid?.deliveryAddress.partner.name;
        terminal.partnerAddressName = terminal.tid?.deliveryAddress.name;
        terminal.partnerFullAddress = getLongAddress(terminal.tid?.deliveryAddress);
        terminal.terminalTypeName = terminal.terminalType?.name;
        terminal.statusName = getStateName(terminal.state!);
        if(terminal.terminalContract){
          if(moment(terminal.terminalContract?.contractedStart).year() >= 3000)
            terminal.outDate = null;
          else
            terminal.outDate = moment(terminal.terminalContract.contractedStart).format('YYYY-MM-DD');
          if(moment(terminal.terminalContract.contractedEnd).year() >= 3000)
            terminal.inDate = null;
          else
            terminal.inDate = moment(terminal.terminalContract.contractedEnd).format('YYYY-MM-DD');
        }
        else{
          terminal.outDate = null;
          terminal.inDate = null;
        }
        terminal.fee = terminal.tid?.rentalFee;
        terminal.tidString = terminal.tid?.terminalId;
      });
      data = data.filter((terminal: TerminalModel) => {
        return showedStates.map((state: TerminalState) => state.key).includes(terminal.state!);
      });
      setTerminals(data);
      setFilter({...filter}); //to trigger the filtering
    }
  }

  //paging
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  //changing row amount
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  //change filter
  const onFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>, 
    key: string
  ): void => {
    let newFilter = {...filter};
    (newFilter as any)[key] = event.target.value;
    setFilter(newFilter);
  };

  //on swap request, show the new request
  const onSwapRequest = (terminal: TerminalModel) => {
    setSwappingTerminal(terminal);
    setRequestDialog(true);
  };

  //get the data on load
  useEffect(() => {
    getTerminals();
    getTerminalTypes();
  // eslint-disable-next-line
  }, []);

  //accordion handling
  const accordionChange = (panel: string) => (): void => {
    if (expanded === panel)
      setExpanded(false);
    else
      setExpanded(panel);
  };  

  // --------------------------------------------------------------Return---
  return (
    <Paper style={style.paper}>

      {/* Header */}
      <Typography variant="h3" style={style.title}>Terminálok listája</Typography>
      <Container style={style.container}>
        <TextField
          variant="outlined"
          value={filter.name}
          onChange={e => onFilterChange(e, 'name')}
          style={style.filterTextField}
          label="Ügyfél"
        />
        <TextField
          variant="outlined"
          value={filter.serial}
          onChange={e => onFilterChange(e, 'serial')}
          style={style.filterTextField}
          label="Gyári szám"
        />
        <Select
          value={filter.state.toString()}
          style={style.filterTextField}
          onChange={e => onFilterChange(e, 'state')}
        >
          <MenuItem value={"all"}>
            Összes státusz
          </MenuItem>
          {showedStates.map((state: TerminalState, i: Key) => {
            return(
              <MenuItem value={state.key} key={i}>
                {state.desc}
              </MenuItem>
            )
          })}
        </Select>
        <Select
          value={filter.type.toString()}
          style={style.filterTextField}
          onChange={e => onFilterChange(e, 'type')}
        >
          <MenuItem value={"all"}>
            Összes terminál típus
          </MenuItem>
          {terminalTypes.map((type: TerminalTypeModel, i: Key) => {
            return(
              <MenuItem value={type.terminalTypeCode} key={i}>
                {type.name}
              </MenuItem>
            )
          })}
        </Select>
      </Container>

      {/* Table */}
      <TableContainer style={style.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {headMemo}
              <TableCell style={style.tableHeadCell}/>
              <TableCell style={style.tableHeadCell}/>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTerminals
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((terminal: TerminalModel, i: Key): JSX.Element => {
                return (
                  <React.Fragment key={i}>
                    {/* Base data */}
                    <TableRow
                      onClick={accordionChange(terminal.terminalCode.toString())}
                      style={style.dataRow}
                    >
                      {tableKeys.map((tableKey: TerminalTableKey, j: Key): JSX.Element => {
                        return (
                          <TableCell
                            align={tableKey.align ?? 'left'}
                            style={style.dataCell}
                            key={j}
                          >
                            {(terminal as any)[tableKey.key]}
                          </TableCell>
                        )
                      })}
                      <TableCell
                        align={'center'}
                        style={style.dataCell}
                      >
                        {terminal.state === 'K' &&
                          <>
                            {terminal.isTakenBack &&
                              <Button
                                onClick={() => {setReturningTerminal(terminal); setReturningOpen(true);}}
                              >
                                Visszavétel
                              </Button>
                            }
                            {terminal.isReplacementNeed &&
                              <Button
                                color='error'
                                onClick={() => {onSwapRequest(terminal);}}
                              >
                                Csereigény
                              </Button>
                            }
                          </>
                        }
                      </TableCell>
                    </TableRow>

                    {/* Additional data */}
                    <TableRow>
                      <TableCell style={style.accordionCell} colSpan={tableKeys.length + 1}>
                        <Accordion expanded={expanded === terminal.terminalCode.toString()}>
                          <AccordionSummary />
                          <AccordionDetails style={style.accordionDetails}>
                            <Grid container spacing={4}>
                              {gridKeys.map((gridKey: TerminalGridKey, j: Key): JSX.Element => {
                                return (
                                  <Grid key={j} item md={gridKey.md ?? 3} sm={gridKey.sm ?? 6} xs={gridKey.xs ?? 6}>
                                    <Typography key={1} style={style.gridLabel} align={gridKey.align ?? 'left'}>{gridKey.label}</Typography>
                                    <Typography key={2} align={gridKey.align ?? 'left'}>{(terminal as any)[gridKey.key]}</Typography>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        component="div"
        count={filteredTerminals.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Return modal */}
      <Dialog
        TransitionComponent={Transition}
        maxWidth={false}
        PaperProps={{style:{width: '100%'}}}
        open={isReturningOpen}
        onClose={() => setReturningOpen(false)}
      >
        <ReturnTerminal returningTerminal={returningTerminal} setReturningOpen={setReturningOpen} getTerminals={getTerminals}/>
      </Dialog>

      {/* Create modal */}
      <Dialog
        TransitionComponent={Transition}
        maxWidth={false}
        PaperProps={{style:{width: '100%'}}}
        open={isRequestDialog}
        onClose={() => setRequestDialog(false)}
      >
        <CreateTerminalRequest 
          getTerminalRequests={getTerminals}
          setDialogOpen={setRequestDialog} 
          swapTerminal={swappingTerminal} 
          title={'Meghibásodás miatt csere terminál igény leadása'}
        />
      </Dialog>
    </Paper>
  );
}

const showedStates: TerminalState[] = [
  {key: 'S', desc: 'Inaktív'}, 
  {key: 'K', desc: 'Aktív'}, 
  // {key: 'O', desc: getStateName('O')}, 
];
