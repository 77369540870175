import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import AddCardIcon from '@mui/icons-material/AddCard';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { AppRoute, StringDictionary } from './GlobalTypes';

// ----------------------------------------------------------------Privates---
// const isTest: boolean = window.location.port === '3000';
const isTest: boolean = false;
export let isDark: boolean = true;
// -----------------------------------------------------------------Exports---
export const dateTimeFormat: string = 'YYYY-MM-DD HH:mm:ss';

export const getAppApiRoutes = {
  sample: {
    addSample: '/sample',
    getSamples: '/sample/getSamples',
    updateSample: '/sample/{id}',
    removeSample: '/sample/{id}',
  },
  login: {
    user: '/authentication/user',
    userTokenRefresh: '/authentication/userTokenRefresh',
  },
  partner: {
    addPartner: '/partner',
    getPartners: '/partner/getPartners',
    getPartner: '/partner/{id}',
    updatePartner: '/partner/{id}',
    removePartner: '/partner/{id}',
  },
  deliveryAddress: {
    removeDeliveryAddress: '/deliveryAddress/{id}',
    updateDeliveryAddress: '/deliveryAddress/{id}',
    addDeliveryAddress: '/deliveryAddress',
    getDeliveryAddress: '/deliveryAddress/{id}',
  },
  terminalType: {
    getTerminalTypes: '/terminalType/getTerminalTypes',
  },
  terminalRequest: {
    addTerminalRequest: '/terminalNeed',
    getTerminalRequests: '/terminalNeed/getTerminalNeeds',
    updateTerminalRequests: '/terminalNeed/{id}',
  },
  terminal: {
    getTerminals: '/terminal/getTerminals',
    updateTerminal: '/terminal/{id}',
    terminalTakeBack: '/terminal/terminalTakeBack/{id}',
    terminalRelocation: '/terminal/terminalRelocation/{id}',
  },
  terminalAccessory: {
    getTerminalAccessory: '/terminalAccessory/getTerminalAccessories',
  },
  terminalErrorDescription: {
    getTerminalErrorDescriptions: '/terminalErrorDescription/getTerminalErrorDescriptions',
  },
} as const;

export function getAppApiUrlBase(): string {
  return isTest
    // ? 'http://localhost:17590'
    ? 'http://api-micra.maxserver.hu'
    : 'https://terminalapi.micra.hu';
}

export function getFileApiUrlBase(): string {
  return isTest
    ? 'http://maxserver.hu/letoltes/?f='
    : 'https://terminalapi.micra.hu/letoltes/?f=';
}

export const getAppRoutes: StringDictionary = {
  home: '/',
  notFound: '*',
  login: '/login',
  partners: '/partners',
  terminalRequests: '/terminalRequests',
  terminals: '/terminals',
  accordionTableExample: '/examples/accordionTable',
}

export const drawerAppRoutes: AppRoute[] = [
  { text: "Kezdő oldal", icon: <HomeIcon/>, route: getAppRoutes.home, homeShow: false },
  { text: "Ügyfél", icon: <PersonIcon/>, route: getAppRoutes.partners, homeShow: true },
  { text: "Terminál igénylés", icon: <AddCardIcon/>, route: getAppRoutes.terminalRequests, homeShow: true },
  { text: "Terminálok", icon: <PointOfSaleIcon/>, route: getAppRoutes.terminals, homeShow: true },
]
